import {inject, observer} from "mobx-react";
import Switch from '@material-ui/core/Switch';
import Modal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close';
import * as React from "react";
import Backend from "../Backend";
import Account from "../Account";
import Screen from "../components/screen";
import {RouteComponentProps} from "react-router";
import TextInput from "../components/commons/TextInput";
import PasswordInput from "../components/commons/PasswordInput";
import {bind} from "decko";
import {Colors, LightButton} from "../theme";
import {action, observable} from "mobx";
import {FormGroup} from "../model";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import i18n from '../i18n';
import { Validators } from "../helper/validators";
import {DB_USERS, INPUT_TEXT_MAX_LENGTH} from "../Constants";
import {errorMessage, loginError} from "../helper/message.helper";
import {isEmpty, setMeta} from "../helper/utils.helper";
import {showSnackbar} from "../App";
import {Alert} from "@material-ui/lab";
import {PrivacyPolicyText} from "./PrivacyPolicy";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import {TermsText} from "./TermsAndConditions";

/*
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));
const classes = useStyles();
 */
const PrivacyPolicyModal = styled.div`
    background-color: #ffffff;
    margin: 0 auto;
    box-sizing: border-box;
    height: 90%;
    top: 4%;
    right: 0;
    left: 0;
    position: absolute;
    padding: 45px 0 0 0;
    &:focus {outline:0;}
`;
const PrivacyPolicyModalContent = styled.div`
    height: 100%;
    overflow: scroll;
    padding: 0 16px;
`;
type Props = {backend: Backend; account: Account} &  RouteComponentProps<any>;
@inject('backend', 'account')
@observer
export default class Login extends React.PureComponent<Props> {

    @observable private formLogin: FormGroup | null = null;
    @observable private formRegister: FormGroup | null = null;
    @observable private errorMessageRegister: string | null = null;
    @observable private errorMessageLogin: string | null = null;
    @observable private modalVisible: boolean = false;
    private privacyText: boolean = true;
    constructor(props: Props) {
        super(props);
        this.showPolicy = this.showPolicy.bind(this);
        this.showTerms = this.showTerms.bind(this);
        this.handleRegisterPressed = this.handleRegisterPressed.bind(this);
        this.handleLoginPressed = this.handleLoginPressed.bind(this);
        this.formLogin = FormGroup.build({
            email: {
                value: '',
                validators: this.emailValidators,
            },
            password: {
                value: '',
                validators: this.passwordValidators,
            },
        });
        this.formRegister = FormGroup.build({
            policyAccepted: {value: false, validators: [
                [{
                    validate: (val: boolean) => {
                        console.log('validate val ', val);
                        return val;
                    }
                }, i18n.t('register_accept_policy_error')]
                ]},
            password: {
                value: '',
                validators: this.passwordValidators,
            },
            confirmPassword: {
                value: ''
            },
            displayName: {
                value: '',
                validators: this.displayNameValidators,
            },
            email: {
                value: '',
                validators: this.emailValidators,
            },
        });
    }
    private get passwordValidators() {
        return [
            [Validators.required(), i18n.t('validation_required')],
            [Validators.regex(/^\S*$/), i18n.t('validation_passowrd_no_whitespace')],
        ];
    }
    private get emailValidators() {
        return [
            [Validators.required(), i18n.t('validation_required')],
            [Validators.email(), i18n.t('validation_invalid_email')],
        ];
    }
    private get displayNameValidators() {
        return [
            [
                Validators.maxLength(INPUT_TEXT_MAX_LENGTH),
                i18n.t('validation_maxlength', {
                    length: INPUT_TEXT_MAX_LENGTH,
                }),
            ],
        ];
    }
    private get fromInvitation(): boolean {
        return !!this.props.history.location.state;
    }

    componentDidMount() {
        setMeta(i18n.t('title_page_login'), i18n.t('desc_page_login'));
        this.props.account.ifInitialized.then(() => {
            if (this.props.account.isLoggedIn || !this.fromInvitation) {
                this.props.history.push('/')
            }
        });
    }
    @bind
    private onLoginEmailChange(text: string) {
        this.formLogin!.update('email', text);
    }
    @bind
    private onLoginPasswordChange(text: string) {
        this.formLogin!.update('password', text);
    }
    @bind
    private onRegisterDisplayNameChange(text: string) {
        this.formRegister!.update('displayName', text);
    }
    @bind
    private onRegisterEmailChange(text: string) {
        this.formRegister!.update('email', text);
    }
    @bind
    private onRegisterPasswordChange(text: string) {
        this.formRegister!.update('password', text);
    }
    @bind
    private onRegisterConfirmPasswordChange(text: string) {
        this.formRegister!.update('confirmPassword', text);
    }
    @action
    private showPolicy() {
        this.privacyText = true;
        this.modalVisible = true;
    }
    @action
    private showTerms() {
        this.privacyText = false;
        this.modalVisible = true;
    }
    @bind
    private navigate() {
        if (this.fromInvitation) {
            this.props.history.replace(`join/${this.props.history.location.state}`);
        } else {
            this.props.history.goBack();
        }
    }
    @action
    private handleLoginPressed() {
        this.errorMessageLogin = null;
        this.errorMessageRegister = null;
        this.formLogin!.validate();
        if (!this.formLogin!.hasErrors) {
            const email: string = this.formLogin!.get('email');
            const password: string = this.formLogin!.get('password');
            if (isEmpty(email) || isEmpty(password)) {
                showSnackbar({
                    severity: 'error',
                    message: errorMessage({code: 'auth/wrong-password'})
                });
                return;
            }
            this.props.backend.pending = true;
            firebase.analytics().logEvent('login', {provider: 'email'});
            firebase.auth()
                .signInWithEmailAndPassword(email, password)
                .then(credentials => {
                    this.props.backend.pending = false;
                    this.navigate();
                })
                .catch(error => {
                    this.props.backend.pending = false;
                    showSnackbar({
                        severity: 'error',
                        message: errorMessage(loginError(error))
                    });
                });
        }
    }

    private async createUser(user: {email: string, password: string, displayName?: string}) {
        const credential = await firebase.auth().createUserWithEmailAndPassword(
            user.email,
            user.password,
        );
        if (credential.user) {
            if (user.displayName) {
                await credential.user.updateProfile({displayName: user.displayName});
            }
            const profile: any = {
                displayName: user.displayName,
                email: user.email,
                privacyPolicyAccepted: true,
            };
            await firebase.firestore()
                .collection(DB_USERS)
                .doc(credential.user.uid)
                .set(profile, {
                    merge: true,
                });
            return true
        }

        return false;
    }

    @action
    private handleRegisterPressed() {
        this.errorMessageLogin = null;
        this.errorMessageRegister = null;
        this.formRegister!.validate();
        if (this.formRegister!.get('password') !== this.formRegister!.get('confirmPassword')) {
            this.errorMessageRegister = i18n.t('confirm_password_doesnt_match');
            return;
        }
        if (this.formRegister!.controls['policyAccepted'].validationMessage) {
            this.errorMessageRegister = i18n.t('register_accept_policy_error');
            return;
        }
        if (!this.formRegister!.hasErrors) {
            //  console.log('CREATE ACCOUNT ', this.formRegister!.values );
            this.props.backend.pending = true;
            this.createUser(this.formRegister!.values).then(() => {
                this.props.backend.pending = false;
                firebase.analytics().logEvent('account_created');
                this.navigate();
            }).catch(error => {
                this.props.backend.pending = false;
                showSnackbar({
                    severity: 'error',
                    message: errorMessage(error)
                });
            });
        }
    }
    @bind
    private handleAcceptPolicyChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.formRegister!.update('policyAccepted', event.target.checked);
    }
    render() {
        return (
            <Screen logo={true}>
                <>
                    <div className={'login-wrapper'}>
                        <div>{this.fromInvitation && (
                            <Alert severity="info" style={{marginBottom: 16}}>
                                <span>{i18n.t('accept_sharelink_info')}</span>
                            </Alert>
                        )}</div>
                        <div className={'login-content'}>
                            <div style={{flexDirection: 'column'}}>
                                <h3>Login</h3>
                                <TextInput onChange={this.onLoginEmailChange}
                                           onBlur={this.formLogin!.controls['email'].onBlurred}
                                           value={this.formLogin!.get('email')}
                                           label={'Email'}
                                           helperText={this.formLogin!.controls['email'].validationMessage}
                                           error={!this.formLogin!.controls['email'].valid}/>
                                <PasswordInput onChange={this.onLoginPasswordChange}
                                               onBlur={this.formLogin!.controls['password'].onBlurred}
                                               helperText={this.formLogin!.controls['password'].validationMessage}
                                               value={this.formLogin!.get('password')}
                                               label={'Password'}
                                               error={!this.formLogin!.controls['password'].valid}/>
                                <div style={{textAlign: 'right', margin: '16px 0'}}>
                                    <LightButton disabled={this.formLogin!.hasErrors} variant="contained" onClick={this.handleLoginPressed}>
                                        Log in
                                    </LightButton>
                                </div>

                            </div>
                            <div className={'divider'} />
                            <div>
                                <h3>Create an account</h3>
                                <TextInput onChange={this.onRegisterDisplayNameChange}
                                           value={this.formRegister!.get('displayName')}
                                           label={i18n.t('display_name')}
                                           helperText={this.formRegister!.controls['displayName'].validationMessage}
                                           onBlur={this.formRegister!.controls['displayName'].onBlurred}
                                           error={!this.formRegister!.controls['displayName'].valid}/>
                                <TextInput onChange={this.onRegisterEmailChange}
                                           value={this.formRegister!.get('email')}
                                           label={i18n.t('email')}
                                           onBlur={this.formRegister!.controls['email'].onBlurred}
                                           helperText={this.formRegister!.controls['email'].validationMessage}
                                           error={!this.formRegister!.controls['email'].valid}/>
                                <PasswordInput onChange={this.onRegisterPasswordChange}
                                               value={this.formRegister!.get('password')}
                                               label={'Password'}
                                               onBlur={this.formRegister!.controls['password'].onBlurred}
                                               helperText={this.formRegister!.controls['password'].validationMessage}
                                               error={!this.formRegister!.controls['password'].valid} />
                                <PasswordInput onChange={this.onRegisterConfirmPasswordChange}
                                               onBlur={this.formRegister!.controls['confirmPassword'].onBlurred}
                                               helperText={this.formRegister!.controls['confirmPassword'].validationMessage}
                                               value={this.formRegister!.get('confirmPassword')}
                                               label={i18n.t('confirm_password')}
                                               error={!this.formRegister!.controls['confirmPassword'].valid}/>
                                <Switch
                                    checked={this.formRegister!.get('policyAccepted')}
                                    onChange={this.handleAcceptPolicyChange}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <span>{i18n.t('accept_policy_before')}</span>
                                <a className={'link'} onClick={this.showPolicy} style={{marginRight: 0}}>Private Policy</a>
                                <span> and </span>
                                <a className={'link'} onClick={this.showTerms}>Terms and Conditions</a>
                                <div>
                                    {this.errorMessageRegister && <small style={{color: Colors.danger}}>{this.errorMessageRegister}</small>}
                                </div>
                                <div style={{textAlign: 'right', margin: '16px 0'}}>
                                    <LightButton disabled={this.formRegister!.hasErrors} variant="contained"  onClick={this.handleRegisterPressed}>
                                        Create
                                    </LightButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={this.modalVisible}
                        onClose={() => this.modalVisible = false}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <PrivacyPolicyModal className={'content modal'}>
                            <div style={{position: 'absolute', right: 0, top: 0}}>
                                <IconButton title={'Close'}
                                            component="span"
                                            style={{color: '#2b2b2b'}}
                                            onClick={() => this.modalVisible = false}>
                                    <Close />
                                </IconButton>
                            </div>
                            <PrivacyPolicyModalContent>
                                {this.privacyText ? <PrivacyPolicyText /> : <TermsText />}
                                <div style={{textAlign: 'right', padding: '24px 0'}}>
                                    <LightButton variant="contained" onClick={() => this.modalVisible = false}>Ok</LightButton>
                                </div>
                            </PrivacyPolicyModalContent>
                        </PrivacyPolicyModal>

                    </Modal>
                </>
            </Screen>
        );
    }
}
