import * as React from 'react';
import {oc} from "ts-optchain";
import i18n from '../i18n';
import {inject, observer} from "mobx-react";
import {computed, observable} from "mobx";
import {ApiAuthParams, UserManagementActions} from "../model";
import {apiAuthParams} from "../helper/url.helper";
import Backend from "../Backend";
import ResetPassword from "../components/auth/ResetPassword";
import RecoverEmail from "../components/auth/RecoverEmail";
import VerifyEmail from "../components/auth/VerifyEmail";
import styled from "styled-components";
import Screen from "../components/screen";
import {RouteComponentProps} from "react-router";
import {setMeta} from "../helper/utils.helper";

// https://medium.com/@c_innovative/implementing-password-reset-can-be-a-tricky-but-inevitable-task-737badfb7bab
/*
m.apelgrim+api1@gmail.com
http://192.168.178.52:3000/api/auth?mode=resetPassword&oobCode=2NP_waS3DIxZHopaMvSURA6Lhz_3kyJP00jHU0zhgBcAAAFxxYHU4A&apiKey=AIzaSyCnHBqvghe9SahggkqSbCbygbyPJvcvuHg&lang=en
 */

const MODES = ['resetPassword', 'recoverEmail', 'verifyEmail'];


const AuthWrapper = styled.div`
    margin: 36px auto;
    max-width: 400px;
`;

type Props = {backend: Backend} &  RouteComponentProps<any>;
@inject('backend')
@observer
export default class ApiAuth extends React.PureComponent<Props> {

    @observable
    params: Partial<ApiAuthParams> | null = null;

    private get areParamsValid(): boolean {
        return !!(this.params && this.params.mode && this.params.oobCode && this.params.apiKey)
            && MODES.indexOf(this.params.mode) > -1;
    }

    @computed
    private get mode(): UserManagementActions {
        return oc(this.params).mode('none');
    }


    public componentDidMount() {
        setMeta(i18n.t('title_page_api'), i18n.t('desc_page_api'));
        this.params = apiAuthParams();
        if (!this.areParamsValid) {
            this.props.history.push('/');
        }
    }

    private get authParams(): ApiAuthParams {
        return {
            mode: oc(this.params).mode('none'),
            oobCode: oc(this.params).oobCode('none'),
            apiKey: oc(this.params).apiKey('none'),
            lang: oc(this.params).lang('en'),
        }
    }

    private renderContent() {

        if (!this.areParamsValid) {
            return null;
        }
        switch (this.mode) {
            case 'resetPassword': {
                return <ResetPassword params={this.authParams}/>
            }
            case 'recoverEmail': {
                return <RecoverEmail  params={this.authParams}/>
            }
            case 'verifyEmail': {
                return <VerifyEmail  params={this.authParams}/>
            }
        }
        return null;
    }

    render() {
        return (
            <Screen logo={true} waitFor={false}>
                <AuthWrapper >
                    {this.renderContent()}
                </AuthWrapper>
            </Screen>
        );
    }

}




