import * as React from "react";
import {observer} from "mobx-react";
import {bind} from "decko";
import styled from "styled-components";
import {Button} from "@material-ui/core";
import Cookies from 'universal-cookie';

const Wrapper = styled.div`
    flex-direction: row;
    max-width: 360px;
    padding: 16px;
    align-items: center;
    display: flex;
`;
const Content = styled.div`
    padding-left: 24px;
`;
const StyledImg = styled.img`
    width: 50px;
`;

interface Props {
    onCancel: () => void
}

@observer
export default class CookieHint extends React.PureComponent<Props> {



    public componentDidMount() {
    }

    @bind
    private onCancel() {
        this.props.onCancel();
    }
    @bind
    private onAccept() {
        const cookies = new Cookies();
        cookies.set('cookieAccepted', true, { path: '/' });
        this.onCancel();
    }
    render() {
        return (
            <Wrapper>
                <StyledImg src={require('../assets/img/cookie.svg')} alt={'Oyeyku'} />
                <Content>
                    <strong>This website uses cookies</strong><br/>
                    <small>We use cookies to analyse our traffic. <br/><a href={'/cookie-policy'}>Learn more</a></small>
                    <div style={{alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex'}}>
                        <Button onClick={this.onCancel}>Cancel</Button>
                        <Button onClick={this.onAccept} color="primary">Accept</Button></div>
                </Content>

            </Wrapper>
        );
    }
}
