import {inject, observer} from "mobx-react";
import * as React from "react";
import {ApiAuthParams, InjectedBackendProps} from "../../model";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import styled from 'styled-components';
import {
    Button,
    CircularProgress,
} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {observable} from "mobx";
import {bind} from "decko";
import PasswordInput from "../commons/PasswordInput";


const PasswordContent = styled.div`
    display: flex;
    flex-direction: column;
`;
const SubmitButton = styled(Button)`
    align-self: flex-end !important;
    color: #ffffff !important;
    margin: 16px 0;
`;

type Props = {params: ApiAuthParams};

@inject('backend')
@observer
export default class ResetPassword extends React.PureComponent<Props> {

    @observable
    private status: 'pending' | 'success' | 'failure' | 'form';

    @observable
    private errorAlert: {title?: string, message: string} | null = null;

    private email: string = 'm.apelgrim@gmail.com';

    @observable
    private newPassword: string;

    @observable
    private confirmPassword: string;

    constructor(props: Props) {
        super(props);
        this.status = 'pending';
        this.newPassword = '';
        this.confirmPassword = '';
    }

    private get injected() {
        return this.props as Props & InjectedBackendProps;
    }

    private get actionCode(): string {
        return this.props.params.oobCode;
    }

    @bind
    private onPasswordChange(text: string) {
        this.errorAlert = null;
        this.newPassword = text;

    }

    @bind
    private onConfirmationChange(text: string) {
        this.errorAlert = null;
        this.confirmPassword = text;
    }

    @bind
    private handleResetPassword() {
        this.errorAlert = null;
        if (!this.newPassword || this.newPassword.trim().length === 0) {
            this.errorAlert = {message: 'The text can not be empty.'};
            return;
        }
        if (this.newPassword !== this.confirmPassword) {
            this.errorAlert = {message: 'Password confirmation doesn\'t match Password.'};
            return;
        }
        if (this.newPassword.trim().length < 6) {
            this.errorAlert = {message: 'Password is too short.'};
            return;
        }
        this.status = 'pending';
        // Save the new password.
        firebase.auth().confirmPasswordReset(
            this.actionCode,
            this.newPassword
        ).then(resp => {
            // Password reset has been confirmed and new password updated.
            firebase.analytics().logEvent('reset_password');
            this.status = 'success';
        }).catch(error => {
            // console.error(error.message);
            // auth/invalid-action-code The action code is invalid. This can happen if the code is malformed, expired, or has already been used.
            if (error.code === 'auth/invalid-action-code') {
                this.errorAlert = {message: 'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.'};
            } else {
                this.errorAlert = {message: 'An error has occured.'};
            }

            this.status = 'form';
        });
    }

    public componentDidMount() {
        firebase.auth().verifyPasswordResetCode(this.actionCode)
            .then(email => {
                this.email = email;
                this.status = 'form';
            })
            .catch(error => {
                // // auth/invalid-action-code
                this.status = 'failure'; // 'pending' | 'success' | 'failure' | 'form';
            });
    }
    private renderAlert() {
        if (!this.errorAlert) {
            return null;
        }
        return (
            <Alert severity="error">
                {this.errorAlert.message}
            </Alert>
        );
    }

    private renderContent() {
        if (this.status === 'pending') {
            return <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div>;
        }
        if (this.status === 'success') {
            return (
                <>
                    <h3>Password changed</h3>
                    <p>You can now sign in with your new password</p>
                </>
            );
        }

        if (this.status === 'form') {
            return (
                <>
                    <PasswordContent>
                        <h3>Reset your password</h3>
                        <p>for <span style={{fontStyle: 'italic'}}>{this.email}</span></p>
                        <PasswordInput onChange={this.onPasswordChange}
                                       value={this.newPassword} label={'Password'}
                                       error={false}
                        />
                        <PasswordInput onChange={this.onConfirmationChange}
                                       value={this.confirmPassword} label={'Confirm password'}
                                       error={false}
                        />

                        <div style={{ margin: '16px 0', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <SubmitButton variant="contained" color="primary" onClick={this.handleResetPassword}>Change</SubmitButton>
                        </div>
                        <div style={{width: '100%'}}>{this.renderAlert()}</div>
                    </PasswordContent>

                </>
            );
        }
        if (this.status === 'failure') {
            return (
                <Alert severity="error">
                    <AlertTitle>Try resetting your password again</AlertTitle>
                    Your request to reset your password has expired or the link has already been used
                </Alert>
            );
        }
        return null;
    }
    render() {
        return this.renderContent();
    }
}


