import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import {observer} from "mobx-react";
import * as React from "react";
import {computed} from "mobx";
import {bind} from "decko";
import {FormControl} from "@material-ui/core";

const StyledTextField = styled(TextField)`
    width: 100%;
`;

interface TextInputProps {
    onChange: (text: string) => void;
    onBlur?: () => void;
    value: string;
    error?: boolean;
    label: string;
    helperText?: string;
}

@observer
export default class TextInput extends React.PureComponent<TextInputProps> {


    @computed
    private get value() {
        return this.props.value;
    }

    @bind
    private handleChange(event: any) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value)
        }
    }

    @bind
    private handleMouseDownPassword(event: any){
        event.preventDefault();
    }

    render() {
        return (
            <FormControl>
                <StyledTextField
                    type={'text'}
                    value={this.value}
                    label={this.props.label}
                    onBlur={this.props.onBlur ? this.props.onBlur : () => null}
                    error={this.props.error}
                    helperText={this.props.helperText}
                    onChange={this.handleChange}
                />
            </FormControl>
        );
    }
}
