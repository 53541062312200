import Backend from "./Backend";
import {action, computed, observable} from "mobx";
import {bind} from "decko";
import {isEmpty} from "./helper/utils.helper";

export type UserManagementActions  = 'resetPassword' | 'recoverEmail' | 'verifyEmail' | 'none';
export interface ApiAuthParams {
    mode: UserManagementActions;
    oobCode: string;
    apiKey: string;
    lang: string
}

export type InjectedBackendProps = {backend: Backend};

export class FormControl {
    @observable
    value: any;
    @observable
    validators?: any[];
    @observable
    valid: boolean = true;
    @observable
    blurred?: boolean;
    @observable
    validationMessage?: string;
    @observable
    disabled?: boolean;
    constructor(value: any, validators?: any[]) {
        this.value = value;
        this.validators = validators;
    }
    public validate(force?: boolean) {
        if (Array.isArray(this.validators) && this.disabled !== true && (this.blurred || force)) {
            for (const validator of this.validators) {
                if (!validator[0].validate(this.value)) {
                    this.valid = false;
                    this.validationMessage = validator[1];
                    return;
                }
            }
        }
        this.valid = true;
        this.validationMessage = '';
    }
    @bind
    public onBlurred() {
        this.blurred = true;
        this.validate();
    }
}
export type FormControls = {[field: string]: FormControl};
export class FormGroup {
    public valid?: boolean;
    @observable private _controls: FormControls = {};
    private addOnValidChangedCallback?: any;
    public static build(controls: {[field: string]: Pick<FormControl, 'value' | 'validators'>}): FormGroup {
        const form = new FormGroup();
        form._controls = Object.keys(controls).reduce((map: FormControls, name) => {
            const control = controls[name];
            map[name] = new FormControl(control.value, control.validators);
            return map;
        }, {});
        return form;
    }

    public get(field: string): any {
        return this._controls[field].value;
    }

    @computed
    public get controls() {
        return this._controls;
    }
    public update(field: string, value: any) {
        const control = this._controls[field];
        if (control) {
            control.value = value;
            control.validate();
        } else {
            console.warn('FormGroup.update Field ' + field + ' doesnt exists.');
        }
    }

    @computed
    public get hasErrors(): boolean {
        for (const field of Object.keys(this._controls)) {
            const control = this._controls[field];
            if (Array.isArray(control.validators) && control.disabled !== true) {
                for (const validator of control.validators) {
                    if (!validator[0].validate(control.value)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    @action
    public validate() {
        const oldValid = this.valid;
        for (const field of Object.keys(this._controls)) {
            const control = this._controls[field];
            control.validate(true);
        }
        this.valid = !this.hasErrors;
        if (this.addOnValidChangedCallback && oldValid !== this.valid) {
            this.addOnValidChangedCallback(this.valid);
        }
    }
    public addOnValidChanged(callback: any) {
        this.addOnValidChangedCallback = callback;
    }
    public get values() {
        const data: any = {};
        for (const field of Object.keys(this._controls)) {
            const control = this._controls[field];
            data[field] = control.value;
        }
        return data;
    }
}
export class OyeykuException {
    public static ERROR_CODE_OBJECT_REQUIRED = 'model/object_required';
    public static ERROR_CODE_OBJECT_NOT_ALLOWED = 'model/object_not_allowed';
    public static ERROR_CODE_FIELD_REQUIRED_MISSING = 'model/field_required_missing';
    public static ERROR_CODE_OBJECT_EMPTY_SNAPSHOT = 'model/object_from_empty_snapshot';
    public static ERROR_CODE_EMPTY_FIELD = 'empty_field';
    public static ERROR_CODE_OPERATION_NOT_ALLOWED = 'operation_not_allowed';
    public static ERROR_CODE_OBJECT_NULL = 'object_is_null';
    public static ERROR_CODE_REFERENCE_NULL = 'reference_is_null';
    public static ERROR_CODE_BASKET_NOT_FOUND = 'basket_not_found';
    public static ERROR_CODE_AUTH_USER_CANCELED = 'auth/user-cancelled';
    public static ERROR_CODE_AUTH_NO_USER = 'auth/no-user';
    public static ERROR_CODE_AUTH_NO_TOKEN = 'auth/no-access-token';
    message: string;
    code: string;
    data: any;
    constructor(_message: string, _code?: string, _data?: any) {
        this.message = _message;
        this.code = _code || '';
        this.data = _data;
    }
}
export interface Message {
    id?: string;
    title?: string;
    linkText?: string;
    closeable?: boolean;
    linkUrl?: string;
    text: string;
    severity: 'success' | 'warning' | 'danger';
    color?: string;
    onClose?: any;
    onOK?: any;
}
export enum ItemType {
    common = 0,
    mandatory = 1,
    incognito = 2,
    excluded = 3,
}

export enum InvitationState {
    sent = 1,
    joined = 2,
    rejected = 3,
    failure = 4,
}
export enum BasketType {
    simple = 0,
    extended = 1,
}
export enum BasketCategory {
    celebration = 1,
    trip = 2,
    other = 3,
    gift = 4,
}

export class UserInfo {
    public static displayName(user: any): string {
        if (user) {
            if (!isEmpty(user.displayName)) {
                return user.displayName;
            }
            if (!isEmpty(user.email)) {
                return user.email.split('@')[0];
            }
        }
        return '';
    }
}
