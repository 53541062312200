import {ApiAuthParams} from "../model";


export function queryString(params: object) {
    return Object.entries(params)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
        .join('&');
}

export function queryParams<T>(query: string): T {
    if (!query) {
        return {} as T;
    }
    const queryObj: any = {};
    return query.split('&').reduce((map, token) => {
        const params = token.split('=');

        map[params[0]] = params.length > 1  ?  decodeURI(params.slice(1).join('=')) : null;

        return map;
    }, queryObj) as T;
}
export function getQuery(dirtyUrl?: string | null) {
    if (!dirtyUrl) {
        return '';
    }
    try {
        const url = !dirtyUrl.toLowerCase().startsWith('http') ? `http://${dirtyUrl}` : dirtyUrl;
        const urlObj = new URL(url);
        return `${urlObj.search.replace('?', '')}`;
    } catch(e) {
        return '';
    }
}

export function apiAuthParams(): Partial<ApiAuthParams> {
    // eslint-disable-next-line no-restricted-globals
    return queryParams< Partial<ApiAuthParams>>(getQuery(location.href));
}
// https://www.oyeyku.com/app/join/?i=iv1j8G81PNSYSXl0fqVN
// http://localhost:3000/app/join/?i=iv1j8G81PNSYSXl0fqVN
export function invitationId(): string {
    // eslint-disable-next-line no-restricted-globals
    const query = queryParams< Partial<{i?: string}>>(getQuery(location.href));
    return query.i || '';
}
