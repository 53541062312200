import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as Constants from "../Constants";

const DEFAULT_ERROR = {
    message: 'Http request not ok',
    code: 500
};

export class HttpRequestError {
    public static readonly GONE = 410;

    private _code: number;
    private _message: string;

    public static async fromResponse(response: Response) {
        return new this(await response.text(), response.status);
    }

    constructor(message = DEFAULT_ERROR.message, code = DEFAULT_ERROR.code) {
        this._message = message;
        this._code = code;
    }

    get code() {
        return this._code;
    }
    get message() {
        return this._message;
    }
}

export default class HttpRequest {
    static host = Constants.BACKEND_HOST;

    private static async getAuthHeaders() {
        const currentUser = firebase.auth().currentUser;
        const token = currentUser
            ? await currentUser.getIdToken()
            : '';

        return {
            Authorization: `Bearer ${token}`
        };
    }

    static get(route: string) {
        return this.request(route, 'GET', undefined);
    }

    static put(route: string, params?: object) {
        return this.request(route, 'PUT', params);
    }

    static post(route: string, params?: object) {
        return this.request(route, 'POST', params);
    }

    static patch(route: string, params?: object) {
        return this.request(route, 'PATCH', params);
    }

    static delete(route: string, params?: object) {
        return this.request(route, 'DELETE', params);
    }

    static async request(route: string, method: string, params?: object) {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            dataType: 'json'
        };
        const body = params ? JSON.stringify(params) : undefined;

        return this.fetch(route, method, headers, body);
    }

    static async requestBlob(route: string, method: string, blob: ArrayBuffer) {
        const headers = {
            'Content-Type': 'application/octet-stream'
        };

        return this.fetch(route, method, headers, blob);
    }

    static async fetch(path: string, method: string, requestHeaders: any, body?: string | ArrayBuffer) {
        const headers = {
            ...(await this.getAuthHeaders()),
            ...requestHeaders
        };
        const options = Object.assign({ method, headers }, body ? { body } : null);
        const response = await fetch(`${this.host}${path}`, options);

        if (!response.ok) {
            throw await HttpRequestError.fromResponse(response);
        }

        return response.json();
    }
}
