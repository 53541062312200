import {inject, observer} from "mobx-react";
import * as React from "react";
import {ApiAuthParams, InjectedBackendProps} from "../../model";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import {observable} from "mobx";
import {CircularProgress} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
type Props = {params: ApiAuthParams};

@inject('backend')
@observer
export default class VerifyEmail extends React.PureComponent<Props> {
    @observable
    private status: 'pending' | 'success' | 'failure';

    constructor(props: Props) {
        super(props);
        this.status = 'pending';
    }

    private get injected() {
        return this.props as Props & InjectedBackendProps;
    }

    private get actionCode(): string {
        return this.props.params.oobCode;
    }

    public componentDidMount() {
        firebase.auth().checkActionCode(this.actionCode)
            .then(info => {
                return firebase.auth().applyActionCode(this.actionCode);
            })
            .then(() => {
                firebase.analytics().logEvent('verify_email');
                this.status = 'success';
            })
            .catch(error => {
                this.status = 'failure';
            });
    }
    private renderContent() {
        if (this.status === 'pending') {
            return <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div>;
        }
        if (this.status === 'success') {
            return (
                <>
                    <h3>Your email has been verified</h3>
                    <p>You can now sign in with your new account</p>
                </>
            );
        }
        if (this.status === 'failure') {
            return <Alert severity="error">Promblem with the verification.</Alert>;
        }
        return null;
    }
    render() {
        return (
            <div>{this.renderContent()}</div>
        );
    }
}
