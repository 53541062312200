import {version as appVersion} from "../package.json";

export const APP = 'Oyeyku';
// gcloud config set project oyeyku-55386
export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyCnHBqvghe9SahggkqSbCbygbyPJvcvuHg",
    authDomain: "oyeyku-55386.firebaseapp.com",
    databaseURL: "https://oyeyku-55386.firebaseio.com",
    projectId: "oyeyku-55386",
    storageBucket: "oyeyku-55386.appspot.com",
    messagingSenderId: "733754392448",
    appId: "1:733754392448:web:646714db97bbb707664b65",
    measurementId: "G-9JXTPJTT53"
};
export const BACKEND_HOST = 'https://europe-west1-oyeyku-55386.cloudfunctions.net/api';
export const GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=com.oyeyku';
export const APPLE_STORE = 'https://apps.apple.com/app/oyeyku/id1494636558';
export const DEBUG = true;
export const animationDuration = 250;
export const APP_VERSION = appVersion;
export const APP_BUILD_NUMBER = 1;
export const APP_DESCRIPTION = 'Collect things with your friends for common activities.';
export const DEVELOPER = 'Maciej Wilanowski';
export const DEVELOPER_EMAIL = 'maciej@apelgrim.com';
export const HOST = 'https://oyeyku.com';
export const URL_SCHEME_PREFIX = 'oyeyku://oyeyku/';
export const FIREBASE_DYNAMIC_LINK = 'https://oyeyku.com/app';
export const ANDROID_PACKAGE_NAME = 'com.oyeyku';
export const DB_BASKETS = 'baskets';
export const DB_PARTICIPANTS = 'participants';
export const DB_BASKET_USERS = 'basket_users';
export const DB_BASKET_PROGRESS = 'basket_progress';
export const DB_PROGRESS = 'progress';
export const DB_USER_BAKSETS = 'user_baskets';
export const DB_DYNAMIC_LINKS = 'dynamic_links';
export const DB_ALERTS = 'alerts';
export const DB_PRIVATE_ITEMS = 'private_items';
export const DB_BASKET_ITEMS = 'basket_items';
export const DB_ITEMS = 'items';
export const DB_LISTENERS = 'listeners';
export const DB_USERS = 'users';
export const DB_FEEDBACK = 'feedback';
export const DB_USER_MESSAGES = 'user_messages';
export const DB_PRIVATE_DATA = 'private_data ';
export const DB_SETTINGS = 'settings';
export const DB_INVITATIONS = 'invitations';
export const DB_NOTIFICATIONS = 'notifications';
export const DB_COMMENTS = 'comments';
export const DB_LOGS = 'logs';
export const DEFAULT_AVATAR = 'assets/imgs/default_avatar.png';
// https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
// 253 - 1, but here should be a smaller number
export const MAX_DISPLAYED_CONTRIBUTORS = 5;
export const NUMBER_MAX_VALUE = 999999;
export const ITEM_MIN_REQUIRED = 1;
export const INPUT_TEXT_MAX_LENGTH = 40;
export const DISPLAY_NAME_MAX_LENGTH = 40;
export const EMAIL_MAX_LENGTH = 100;
export const PASWWORD_MAX_LENGTH = 100;
export const TEXTAREA_MAX_LENGTH = 500; // 1000;
export const CONTACT_TEXTAREA_MAX_LENGTH = 2000; // 1000;
export const URL_MAX_LENGTH = 1000; // 1000;
export const EMAIL_PATTERN = /^\S+@\S+$/;
// @ts-ignore
export const URI_PATTERN = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
export const PASSWORD_PATTERN = /^\S*$/;
export const PASSWORD_MIN_LENGTH = 6;
export const LIST_SIZE_LIMIT = 15;
export const LIST_PAGE_SIZE = 15;
export const FIRST_EXCLUDED_ITEMS_SIZE = 3;
export const LIST_CHAT_PAGE_SIZE = 30;

