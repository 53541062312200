import * as React from 'react';
import i18n from '../i18n';
import Screen from "../components/screen";
import {setMeta} from "../helper/utils.helper";

export default class  extends React.PureComponent {
    public componentDidMount() {
        setMeta(i18n.t('title_page_cookie_policy'), i18n.t('desc_page_cookie_policy'));
    }
    render() {
        return (
            <Screen logo={true} cookie={false} waitFor={false}>
                <div className={'cookie-policy'}>
                    <h2>COOKIE POLICY</h2>
                    <h4>Welcome to Oyeyku!</h4>
                    This Cookie Policy explains how and why cookies and other similar technologies may be stored on and accessed from your device when you use or visit:
                    <ul>
                        <li><a href={'https://www.oyeyku.com'}>https://www.oyeyku.com</a></li>
                    </ul>
                    The information collected through cookies will be under responsibility and in charge of:
                    <ul>
                        <li>Oyeyku</li>
                        <li>Maciej Wilanowski (info@oyeyku.com)</li>
                    </ul>
                    <p>
                        This Cookie Policy should be read together with our Privacy Policy and our Terms and conditions.
                    </p>
                    <p>
                        <strong>
                            By using Oyeyku website (landing page), you accept the use of cookies by this website, in the terms contained in this policy.
                        </strong>
                    </p>
                    <h4>WHAT ARE COOKIES?</h4>
                    <p>
                        “Cookies” are small text files that are stored on your computer or mobile device when you visit a website. They allow the website to recognize your device and remember if you have been to the website before.
                    </p>
                    <p>
                        Cookies are very common web technology, most website use Cookies and have done for years. Cookies are widely used in order to make website work more efficiently, as well as providing information to the owners of website.
                    </p>
                    <p>
                        Cookies are used to measure which parts of the website people visit and to customize your experience. Cookies also provide information that helps us monitor and improve the website’s performance.
                    </p>
                    <h4>REFUSING OR WITHDRAWING CONSENT TO THE USE OF COOKIES</h4>
                    <p>
                        If you do not want Cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some Cookies and to alert you when a Cookie is placed on your device. For further information about how to do so, please refer to your browser ‘help’, ‘tool’, or ‘edit’ section. Please note that if you use your browser settings to block all Cookies, including strictly necessary Cookies, you may not be able to access or use all or parts of the functionalities of the Oyeyku website.
                    </p>
                    <p>
                        If you want to remove previously-stored Cookies, you can manually delete the Cookies at any time. However, this will not prevent the Oyeyku website from placing further Cookies on your device unless and until you adjust your Internet browser setting as described above.
                    </p>
                    <p>
                        We provide the links for the management and blocking of cookies depending on the browser you use:
                    </p>
                    <ul>
                        <li>Internet Explorer: <a href={'http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-10'}>http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-10</a></li>
                        <li>Firefox: <a href={'https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox'}>https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox</a></li>
                        <li>Chrome: <a href={'https://support.google.com/chrome/answer/95647?hl=en'}>https://support.google.com/chrome/answer/95647?hl=en</a></li>
                        <li>Safari: <a href={'https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac'}>https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
                    </ul>
                    <h4>FIRST-PARTY COOKIES</h4>
                    <p>
                        Cookies help us to collect information on how people use our website and which pages they visit. They enable us to monitor the number of visitors and to analyze website usage patterns and trends.
                    </p>
                    <p>
                        We collect this information anonymously, so it does not identify anyone as an individual and no personal information is stored in our Cookies. We always use Cookie data in a responsible way.
                    </p>
                    <p>
                        Our aim is to continually improve the Oyeyku online experience. Cookies help to make our website work better and more efficiently. However, you can stop Cookies from being downloaded to your device from Oyeyku at any time by selecting the appropriate settings in your browser. Most browsers will tell you how to change your browser settings to notify you when a Cookie is being set or updated, or to restrict or block certain types or all Cookies.
                    </p>
                    <h4>USES OF COOKIES</h4>
                    <p>
                        Our Cookies are used for the following purposes:
                    </p>
                    <p>
                        <strong>Strictly Necessary:</strong> These Cookies are essential for Oyeyku website to perform its basic functions.
                    </p>
                    <p>
                        <strong>Security:</strong> We use these Cookies to help identify and prevent potential security risks.
                    </p>
                    <p>
                        <strong>GOOGLE Firebase Analytics.</strong> We use Google Firebase Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the website and platform and improve our service.
                    </p>
                    Please consult Google's privacy policy here:
                    <ul>
                        <li><a href={'https://policies.google.com/privacy'}>https://policies.google.com/privacy</a></li>
                    </ul>
                    <h4>CONTACT US</h4>
                    <p>
                        If you have questions about this Cookie Policy, please contact us at:
                    </p>
                    info@oyeyku.com
                </div>
            </Screen>
        );
    }

}
