import * as React from 'react';
import i18n from '../i18n';
import Screen from "../components/screen";
import {setMeta} from "../helper/utils.helper";

export default class PrivacyPolicy extends React.PureComponent {
    public componentDidMount() {
        setMeta(i18n.t('title_page_privacy_policy'), i18n.t('desc_page_privacy_policy'));
    }
    render() {
        return (
            <Screen logo={true} waitFor={false}>
                <PrivacyPolicyText />
            </Screen>
        );
    }
}

export function PrivacyPolicyText(props: any) {
    return (
        <div>
            <h2>PRIVACY POLICY</h2>
            <h4>Welcome to Oyeyku!</h4>
            <p><strong>Oyeyku</strong> values your privacy. This notice describes what information we collect,
                how we use it, and, if necessary, those with whom we share it. </p>
            <p><strong>By downloading and using the Oyeyku application and website, you are accepting the practices
                described in this Privacy Policy</strong>. Your use of the application and website is also subject to
                our Terms and Conditions.</p>
            <p>
                This Privacy Policy may change from time to time. Your continued use of the application and website
                after we make changes is deemed to be acceptance of those changes, so please check the policy
                periodically for updates. This Privacy Policy has been developed and is maintained in accordance
                with all applicable federal and international laws and regulations, and specifically with the
                California Online Privacy Protection Act (CalOPPA – U.S regulation) and the GDPR (General Data
                Protection Regulation - European regulation).
            </p>
            <h4>GENERAL INFORMATION</h4>
            <p>The personal data of the users that are collected and processed through:</p>
            <ul>
                <li>Oyeyku App (Android and iOS version – Available on Google Play and App store)</li>
                <li><a href={'https://www.oyeyku.com'}>https://www.oyeyku.com</a></li>
            </ul>
            <p>The personal data of the users that are collected and processed through:</p>
            <ul>
                <li>Oyeyku</li>
                <li>Maciej Wilanowski (info@oyeyku.com)</li>
            </ul>
            (Hereinafter referred to as “Oyeyku”).
            <h4>TYPES OF INFORMATION GATHERED</h4>
            <p>
                The information we learn from customers helps personalize and continually improve your experience at
                Oyeyku. Here are the types of information we gather:
            </p>
            <p>
                <strong>Information You Give Us.</strong> You provide information when you download the app, search, read and
                watch the content, register on the app, use the app or communicate with us. As a result of
                those actions, you might supply us with the following information: </p>
                <ul>
                    <li>Display name (optional)</li>
                    <li>Email address</li>
                    <li>Avatar picture (optional)</li>
                    <li>Apple ID and Facebook profile information (login purposes)</li>
                    <li>IP address</li>
                    <li>
                        Any additional information relating to you that you provide to us directly through our websites
                        or indirectly through our websites or online presence such as ‘cookies’.
                    </li>
                </ul>
            <p>
                Oyeyku will not collect any personally identifiable information about you, unless you provide it.
            </p>
            <p>
                <strong>GOOGLE Firebase Analytics.</strong> We use Google Firebase Analytics provided by Google, Inc., USA (“Google”).
                These tool and technologies collect and analyze certain types of information, including IP addresses,
                device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage
                and purchase history, media access control address (MAC Address), mobile unique device identifiers,
                and other similar information via the use of cookies. The information generated by Google Analytics
                (including your IP address) may be transmitted to and stored by Google on servers in the United States.
                We use the GOOGLE Firebase Analytics collection of data to enhance the website and platform and improve
                our service.
            </p>
            Please consult Google's privacy policy here:
            <ul>
                <li><a href={'https://policies.google.com/privacy'}>https://policies.google.com/privacy</a></li>
            </ul>
            <p>
                <strong>Advertising (Google Admob):</strong> We use Google Admob to display relevant advertising to users who download
                and use the application. Users in the European Union may change the settings of the advertising
                displayed to them through their account. This configuration will only be available to users in the European Union.
            </p>
            Please consult Google's privacy policy here:
            <ul>
                <li><a href={'https://policies.google.com/privacy'}>https://policies.google.com/privacy</a></li>
            </ul>
            <p>
                <strong>Automatic Information.</strong> Like many websites, we use "cookies." We obtain certain types of anonymous information which is not personally identifiable when your web browser accesses Oyeyku or third-party content served by or on behalf of Oyeyku on other websites. Some of the information we collect and analyze includes the Internet protocol (IP) address; computer and connection information such as browser type, version, and connection speed; purchase history; and the content you searched for, viewed, and possibly purchased.
            </p>
            <h4>HOW LONG WE KEEP YOUR DATA</h4>
            <p>
                Personal data will be kept for the time necessary for the provision of the services or as long as the user does not close his account or withdraw his consent. The user's personal data will be deleted from our database immediately the user's account is closed or his consent withdrawn.
            </p>
            <h4>HOW WE USE YOUR INFORMATION. </h4>
            <p>
                In general, we use the information we collect primarily to provide, maintain, protect and improve our current application, website (landing page) and services. We use personal information collected through our site as described below and described elsewhere in this Policy to:
            </p>
            <ul>
                <li>Identify you as a user in our system.</li>
                <li>Provide the application.</li>
                <li>User registration.</li>
                <li>Provide the application's functionalities.</li>
                <li>Share activity invitation links.</li>
                <li>Improve our services, website, and how we operate our business.</li>
                <li>Understand and enhance your experience using our website and services.</li>
                <li>Send emails through bulk email delivery platforms such as MailChimp and Klaviyo.</li>
                <li>Respond to your comments or questions and for our support team to provide service;</li>
                <li>Send you related information, including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages.</li>
                <li>Communicate with you about upcoming events and news about services offered by Oyeyku and our selected partners.</li>
                <li>Marketing purposes of Oyeyku.</li>
                <li>Link or combine your information with other information we get from third parties to help understand your needs and provide you with better service.</li>
                <li>Protect, investigate and deter against fraudulent, unauthorized or illegal activity.</li>
            </ul>

            <h4>HOW DO YOU GET MY CONSENT?</h4>
            <p>
                By downloading the application, registering, using the services and providing us with personal information to communicate with you, you agree that we collect and use your information. You can withdraw your consent, contact us through the contact information or the contact page.
            </p>
            <h4>HOW WE SHARE INFORMATION</h4>
            <p>
                Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below.
            </p>
            <p>
                <strong>Third-Party Service Providers.</strong> We employ other companies and individuals to perform functions on our behalf. Examples include analyzing data, providing marketing assistance, providing search results and links. They have access to personal information needed to perform their functions, but may not use it for other purposes.
            </p>
            <p>
                <strong>Business Transfers.</strong> In the event that Oyeyku creates, merges with, or is acquired by another entity, your information will most likely be transferred. Oyeyku will email you or place a prominent notice on our Website before your information becomes subject to another privacy policy.
            </p>
            <p>
                <strong>Protection of Oyeyku and others.</strong> We release personal information when we believe release is appropriate to comply with the law, enforce or apply our Terms and conditions and other agreements, or protect the rights, property, or safety of Oyeyku, our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.
            </p>
            <p>
                <strong>With Your Consent.</strong> Other than as set out above, you will receive notice when personally identifiable information about you might go to third parties, and you will have an opportunity to choose not to share the information.
            </p>
            <p>
                <strong>Anonymous Information.</strong> Oyeyku uses the anonymous browsing information collected automatically by our servers primarily to help us administer and improve the platform and website. We may also use aggregated anonymous information to provide information about the website to potential business partners and other unaffiliated entities. This information is not personally identifiable.
            </p>
            <p>
                <strong>Email Address.</strong> The email address that you supply to us for purposes of receiving our email communications will never be rented or sold to a third party.
            </p>
            <h4>PROTECTING YOUR INFORMATION</h4>
            <p>
                We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input. If transactions are processed on the Website, transaction information is transmitted to and from the Website in encrypted form using industry-standard SSL connections to help protect such information from interception. We restrict authorized access to your personal information to those persons who have a legitimate purpose to know that information to provide products or services to you and those persons you have authorized to have access to such information.
            </p>
            <p>
                Oyeyku follows generally accepted industry standards to protect the personal information submitted to us, both during transmission and once Oyeyku receives it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while Oyeyku strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
            </p>
            <p>
                We will not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so.
            </p>
            <h4>YOUR RIGHTS</h4>
            You have the following rights in relation to your personal data provided to Oyeyku:
            <ul>
                <li>Access your personal data.</li>
                <li>Request the correction of inaccurate data.</li>
                <li>Request its deletion.</li>
                <li>Request limitation of the processing of your data.</li>
                <li>Oppose the treatment of your data.</li>
                <li>Request your portability.</li>
                <li>Not be subject to automated individual decisions.</li>
            </ul>
            <p>
                The Users can exercise all these rights contacting us through the contact information or the contact page and indicating the reason for his request.
            </p>
            <p>
                Without preconception to any other administrative appeal or legal action, the User shall have the right to submit a claim to a Control Authority, in particular in the Member State in which he or she has their habitual residence, place of work or place of the alleged infringement. If you consider that the processing of your personal data is not appropriate to the regulations, as well as in the case of not seeing the exercise of your rights satisfied. The control authority in which the claim was filed will inform the claimant about the course and result of the claim.
            </p>
            <h4>EU MEMBERS’ RIGHTS (GDPR COMPLIANT)</h4>
            <p>
                If you are habitually located in the European Union, you have the right to access, rectify, download or erase your information, as well as the right to restrict and object to certain processing of your information. While some of these rights apply generally, certain rights apply only in certain limited circumstances.
            </p>
            We describe these rights below:
            <ul>
                <li>Access and Porting: You can access your information at any time.</li>
                <li>Rectify, Restrict, Limit, Delete: You can also rectify, restrict, limit or delete much of your information by contact us.</li>
                <li>Object: Where we process your information based on our legitimate interests explained above, or in the public interest, you can object to this processing in certain circumstances. In such cases, we will cease processing your information unless we have compelling legitimate grounds to continue processing or where it is needed for legal reasons.</li>
                <li>Revoke consent: Where you have previously provided your consent, such as to permit us to process health-related data about you, you have the right to withdraw your consent to the processing of your information at any time. For example, you can withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn consent if we have a legal basis to do so or if your withdrawal of consent was limited to certain processing activities.</li>
                <li>Complain: Should you wish to raise a concern about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority</li>
            </ul>
            Additionally, if you are a European resident, we note that we are processing your information in order to fulfil contracts we might have with you or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe.
            <p>You can see more about these rights at:</p>
            <a href={'https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en'}>https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en</a>
            <h4>CALIFORNIA ONLINE PRIVACY PROTECTION ACT COMPLIANCE (CalOPPA)</h4>
            <p>
                CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared.
            </p>
            <p>
                Because we value your privacy, we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act (CalOPPA).
            </p>
            According to the California Online Privacy act, you have the following rights:
            <ul>
                <li>Know what personal data is being collected.</li>
                <li>Know whether your personal data is sold or disclosed and to whom.</li>
                <li>Access to your personal data.</li>
                <li>Request to delete any personal information about you.</li>
                <li>Not be discriminated against for exercising their privacy rights.</li>
            </ul>
            <h4>CHILDREN’S ONLINE PRIVACY PROTECTION</h4>
            <p>
                We are in compliance with the requirements of the GDPR (General Data Protection Regulation), and the California Online Privacy Protection Act (CalOPPA) regarding the protection of the personal data of minors. We do not collect any information from anyone under 13 years of age. Our website, platform and services are all directed to people who are at least 18 years old or older.
            </p>
            <h4>EDITING AND DELETING INFORMATION</h4>
            <p>
                If you believe that any information, we are holding on you is incorrect or incomplete, please write to or email us as soon as possible. We will promptly correct any information found to be incorrect. You can change, modify, rectify and delete your Information at any time, please contact us through the contact information.
            </p>
            <p>
                To opt-out of Oyeyku email, follow the instructions included in the email. Your request should be processed within 48 hours.
            </p>
            <h4>SUPPLIERS AND OTHER THIRD PARTIES</h4>
            <p>
                Except as otherwise expressly included in this Privacy Policy, this document addresses only the use and disclosure of information Oyeyku collects from you. If you disclose your information to others, whether other users or suppliers on Oyeyku, different rules may apply to their use or disclosure of the information you disclose to them. Oyeyku does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. Oyeyku is not responsible for the privacy or security practices of other websites on the Internet, even those linked to or from the Oyeyku site. Oyeyku encourages you to ask questions before you disclose your personal information to others.
            </p>
            <h4>CHANGES TO THIS PRIVACY POLICY</h4>
            <p>
                Oyeyku will post modifications by date to this Privacy Policy to inform you of any changes. Oyeyku reserves the right to modify this Privacy Policy at any time, please review it frequently.
            </p>
            <h4>ENFORCEMENT</h4>
            <p>
                Oyeyku regularly reviews its compliance with this Policy. Please feel free to direct any questions or concerns regarding this Policy or Oyeyku treatment of personal information by contacting us.
            </p>
            <p>
                When we receive formal written complaints, it is Oyeyku policy to contact the complaining user regarding his or her concerns. We will cooperate with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that cannot be resolved between Oyeyku and an individual.
            </p>
            <p>
                If you have questions or concerns about these Privacy Policy and the handling and security of your data on this site, please contact us:
            </p>
            info@oyeyku.com

        </div>
    )
}
