import {OyeykuException} from "../model";
import i18n from '../i18n';
type Error = {code: string; message?: string};

export const errorMessage = (error: any, defaultMsg: string = i18n.t('error_uknow')): string => {
    const code = error.code || '';
    switch (code) {
        case 'auth/user-not-found':
            return i18n.t('auth/user-not-found');
        case 'E_PERMISSION_MISSING':
            return i18n.t('E_PERMISSION_MISSING');
        case 'E_PICKER_NO_CAMERA_PERMISSION':
            return i18n.t('E_PICKER_NO_CAMERA_PERMISSION');
        case 'E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR':
            return i18n.t('E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR');
        case 'images/permission-missing':
            return i18n.t('images/permission-missing');
        case 'auth/invalid-email':
            return i18n.t('auth/invalid-email');
        case 'auth/user-disabled':
            return i18n.t('auth/user-disabled');
        case 'auth/wrong-password':
            return i18n.t('login_password_wrong');
        case 'login_email_password_wrong':
            return i18n.t('login_email_password_wrong');
        case 'auth/too-many-login-requests':
            return i18n.t('auth/too-many-login-requests');
        case 'auth/email-already-in-use':
            return i18n.t('auth/email-already-in-use');
        case 'auth/weak-password':
            return i18n.t('auth/weak-password');
        case 'auth/network-request-failed':
            return i18n.t('auth/network-request-failed');
        case 'auth/create-user-not-allowed':
            return i18n.t('auth/create-user-not-allowed');
        case 'auth/too-many-requests':
            return i18n.t('auth/too-many-requests');
        case 'confirm_password_doesnt_match':
            return i18n.t('confirm_password_doesnt_match');
        case 'firestore/unavailable':
            return i18n.t('firestore/unavailable');
        case 'firestore/permission-denied':
            return i18n.t('firestore/permission-denied');
        case 'database/permission-denied':
            return i18n.t('firestore/permission-denied');
        case 'delete/user-baskets/no-user':
            return i18n.t('delete/user-baskets/no-user');
        case OyeykuException.ERROR_CODE_AUTH_USER_CANCELED:
            return i18n.t('auth/user-cancelled');
        case OyeykuException.ERROR_CODE_AUTH_NO_TOKEN:
            return i18n.t('auth/no-access-token');
        default:
            console.warn('Error msg not recognized code: ' + error.code, error);
            return defaultMsg;
    }
};
export const errorHttpMessage = (code: number) => {
    switch (code) {
        case 400:
            return i18n.t('ErrorHttp_400');
        case 401:
            return i18n.t('ErrorHttp_401');
        case 403:
            return i18n.t('ErrorHttp_403');
        case 404:
            return i18n.t('ErrorHttp_404');
        default:
            return i18n.t('error_uknow');
    }
};

export const loginError = (error: any = {code: ''}): Error => {
    let code = '';
    switch (error.code) {
        case 'auth/user-not-found':
            code = 'login_email_password_wrong';
            break;
        case 'auth/invalid-email':
            code = 'login_email_password_wrong';
            break;
        case 'auth/user-disabled':
            code = 'auth/user-disabled';
            break;
        case 'auth/wrong-password':
            code = 'login_email_password_wrong';
            break;
        case 'auth/too-many-requests':
            code = 'auth/too-many-login-requests';
            break;
        default:
            console.warn('loginError msg not recognized. ', error);
    }
    return {code: code};
};
export const createUserError = (error: any = {code: ''}): Error => {
    return error.code === 'auth/operation-not-allowed'
        ? {
              code: 'auth/create-user-not-allowed',
              message:
                  'email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.',
          }
        : error;
};
export const deleteBasketsError = (error: any = {code: ''}): Error => {
    return error.code === OyeykuException.ERROR_CODE_OBJECT_NULL
        ? {
              code: 'delete/user-baskets/no-user',
              message: 'Error during deletion. The user is not defined.',
          }
        : error;
};
