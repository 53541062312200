import * as React from "react";
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Popover from '@material-ui/core/Popover';
import {inject, observer} from "mobx-react";
import {action, computed, observable} from "mobx";
import {bind} from "decko";
import CookieHint from "./CookieHint";
import Cookies from "universal-cookie";
import Account from "../Account";
import LoadingIndicator from "./commons/LoadingIndicator";
import Backend from "../Backend";
import * as firebase from 'firebase/app';
import {CSSProperties} from "react";
import Header from "./commons/Header";
export type PrettySnackbarParams = {severity: 'success' | 'info' | 'warning' | 'error'; message: string};
interface Props {
    logo?: boolean;
    welcomePage?: boolean;
    waitFor?: boolean;
    cookie?: boolean;
    contentStyle?: CSSProperties;
}

@inject('account', 'backend')
@observer
export default class Screen extends React.PureComponent<Props> {

    @observable
    anchorEl: any = null;

    @observable
    private _loaded = false;


    private get injected() {
        return this.props as Props & {account: Account; backend: Backend};
    }

    @observable
    cookiePopup: boolean = false;

    @action
    public componentDidMount() {
        const cookies = new Cookies();
        this.injected.account.ifInitialized.then(() => {
            this._loaded = true;
            this.injected.backend.pending = false;
        });
        if (!cookies.get('cookieAccepted')) {
            this.cookiePopup = true;
        }
    }
    @computed
    private get user() {
        return this.injected.account.authUser;
    }
    @computed
    private get loaded() {
        return this.props.waitFor === false || this._loaded;
    }

    @computed
    private get pending() {
        return this.props.waitFor !== false && this.injected.backend.pending;
    }

    @bind
    private onPopupClose() {
        this.cookiePopup = false;
        this.anchorEl = null;
    }

    @bind
    private getAnchorEl(element: Element) {
        return this.anchorEl;
    }

    private renderContent() {
        if (this.props.welcomePage) {
            return this.props.children;
        }
        const contentStyle = this.props.contentStyle ? this.props.contentStyle : {};
        return (
            <main style={contentStyle}>
                <div className={'container'}>
                    {this.loaded ? this.props.children : null}
                    {this.pending && <LoadingIndicator />}
                </div>
            </main>
        );
    }
    render() {

        return (
            <>
                <Header className={this.props.welcomePage ? 'welcome' : ''}/>
                {this.renderContent()}
                <footer ref={(ref) => this.anchorEl = ref} className={this.props.welcomePage ? 'welcome' : ''}>
                    <div className={'container'}>
                        <a href={'/'}>
                            Home
                        </a>
                        <a href={'/imprint'}>
                            Impressum
                        </a>
                        <a href={'/privacy-policy'}>
                            Privacy Policy
                        </a>
                        <a href={'/terms-and-conditions'}>
                            Terms and Conditions
                        </a>
                    </div>
                </footer>
                {this.props.cookie !== false && <Popover
                    open={this.cookiePopup}
                    disableScrollLock={true}
                    modal={false}
                    anchorEl={this.getAnchorEl}
                    onClose={this.onPopupClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }} >
                    <CookieHint onCancel={this.onPopupClose}/>
                </Popover>}

            </>
        );
    }
}

export function UserInfo(props: {user: firebase.User | null; onLogout: () => void}) {
    if (!props.user) {
        return null;
    }
    const displayName = props.user.displayName || props.user.email;
    return (<div><span style={{color: '#ffffff'}}>{displayName}</span>
        <IconButton title={'Log out'} aria-label="upload picture" component="span" style={{color: '#ffffff'}} onClick={props.onLogout}>
            <PowerSettingsNew />
        </IconButton>
    </div>);
}
