import React from "react";
import {inject, observer} from "mobx-react";
import Account from "../../Account";
import Backend from "../../Backend";
import styled from "styled-components";
import {computed} from "mobx";
import {bind} from "decko";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import {UserInfo} from "../screen";
const HeaderContent = styled.div`
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
`;
interface Props {
    className?: string;
    showLogo?: boolean;
}
@inject('account', 'backend')
@observer
export default class Header extends React.PureComponent<Props> {
    private get injected() {
        return this.props as Props & {account: Account; backend: Backend};
    }
    @computed
    private get user() {
        return this.injected.account.authUser;
    }
    @bind
    private logout() {
        firebase.auth().signOut().then(() => {
            // eslint-disable-next-line no-restricted-globals
            location.href = '/';
        }).catch(error => {
            console.error(error);
        });
    }
    public render() {
        return (
            <header className={this.props.className ?? ''}>
                <HeaderContent className={'container'}>
                    {this.props.showLogo !== false ? (
                        <a href={'/'} className={'header'}>
                            <img src={require('../../assets/img/oyeyku-white.png')} alt={'Oyeyku'}/>
                        </a>
                    ) : <span />}
                    <UserInfo user={this.user} onLogout={this.logout}/>
                </HeaderContent>
            </header>
        );
    }
}
