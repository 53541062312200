import * as React from 'react';
import {APPLE_STORE, GOOGLE_PLAY} from "../Constants";
import Slider, {Settings} from "react-slick";
import Screen from "../components/screen";
import i18n from '../i18n';
import {debounce} from "decko";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {setMeta} from "../helper/utils.helper";

const SLICK_SETTINGS: Settings = {
    dots: true,
    arrows: true,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    infinite: true,
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                centerMode: true,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '30px',
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 300,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '15px',
                infinite: true,
                dots: true
            }
        }
    ]
};
const SLIDER_IMAGES = [
    require('../assets/img/ios_0_600.png'),
    require('../assets/img/ios_4_600.png'),
    require('../assets/img/ios_3_600.png'),
    require('../assets/img/ios_1_600.png'),
    require('../assets/img/ios_2_600.png'),
    require('../assets/img/ios_6_600.png'),
    require('../assets/img/ios_7_600.png'),
];
interface InfoBoxProps {
    title: string;
    description: string;
    src: any;
    srcAlt: string;
}
class InfoBox extends React.PureComponent<InfoBoxProps> {
    render() {
        return (
            <div className={'feature-box'}>
                <h3>{this.props.title}</h3>
                <div className={'image'}><img src={this.props.src} alt={this.props.srcAlt}/></div>
                <div className={'description'}>
                    {this.props.description}
                </div>
            </div>
        );
    }
}
@observer
export default class Home extends React.PureComponent {
    @observable
    private showStickyHeader: boolean = false;
    @observable
    private headerAnimationClass: string = '';
    private featuresEl?: any;
    private sliderRef?: any;
    constructor(props: any) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }
    boxes = [
        {
            title: 'Create',
            description: 'Create a list of things you want to take with you on a trip, to buy as a gift or just to collect for another occasion or activity.',
            src: require('../assets/img/box1.png'),
            srcAlt: 'Create'
        },
        {
            title: 'Share',
            description: 'Share this list with your friends. Invite them to collect things together by sending them a link.',
            src: require('../assets/img/box2.png'),
            srcAlt: 'Share'
        },
        {
            title: 'Collect',
            description: 'Collect things with other participants for a joint activity. Share with others which items or how many you want to take.',
            src: require('../assets/img/box3.png'),
            srcAlt: 'Collect'
        }
    ];

    @action
    private toggleStickyHeader(visible: boolean) {
        if (visible) {
            this.headerAnimationClass = 'fadeInDown';
            this.showStickyHeader = true;
        } else {
            this.headerAnimationClass = 'fadeOutUp';
            setTimeout(() => {
                this.showStickyHeader = false;
            }, 400);
        }
    }
    @debounce(300)
    private handleScroll(event: Event) {
        if (window.pageYOffset + 60 > this.featuresEl.offsetTop) {
           this.toggleStickyHeader(true);
        } else {
            this.toggleStickyHeader(false);
        }

    }
    @debounce(300)
    private handleResize(event: Event) {
        // console.log('this.sliderRef ', this.sliderRef)
    }
    componentDidMount() {
        setMeta(i18n.t('title_page'), i18n.t('desc_page'));
        // window.addEventListener('scroll', this.handleScroll);
        // window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        // window.removeEventListener('scroll', this.handleScroll);
        // window.removeEventListener('resize', this.handleResize);
    }
    /*
                  <Header className={'sticky animate__animated animate__' + this.headerAnimationClass}/>
                {this.showStickyHeader && <Header className={'sticky animate__animated animate__' + this.headerAnimationClass}/>}

                                    <a href={'/'} className={'header'}>
                                    <img width={120} src={require('../assets/img/oyeyku-white.png')} alt={'Oyeyku'}/>
                                </a>
     */
    render() {
        return (
            <Screen welcomePage={true}>
                <section id={'welcome'}>
                    <div className={'container'}>
                        <div className={'boxes'}>
                            <div style={{}} className={'box'}>
                                <img className={'screenshot'} src={require('../assets/img/ios_0_600.png')}/>
                            </div>
                            <div className={'box'}>
                                <h1>Collect things with your friends for joint activities</h1>
                                <p className={'app-description'}>
                                    Oyeyku is an easy & fun app that allows you to collect items with others. Were you invited to a
                                    baby shower, a wedding, or a trip with friends? Or perhaps you want to paint your house and
                                    ask your friends for help? Oyeyku’s hands-on lists will help you collect everything needed,
                                    regardless of the event. Create activities and add your friends. Tick off items as you collect
                                    them and track your progress!
                                </p>
                                <DownloadOyeyku />
                            </div>

                        </div>

                    </div>

                </section>
                <section id={'features'} ref={(ref) => this.featuresEl = ref}>
                    <div className={'container'}>
                        <h2>Features</h2>
                        <div className={'boxes'}>
                            {this.boxes.map((box, index) => <InfoBox key={`key-${index}`}
                                                                     srcAlt={box.srcAlt}
                                                                     title={box.title} description={box.description}
                                                                     src={box.src}/>)}
                        </div>
                    </div>
                </section>
                <section id={'screenshots'}>
                    <div className={'container'}>
                        <h2>Screenshots</h2>
                        <div className={'wrapper'}>
                            <Slider {...SLICK_SETTINGS} ref={(ref) => this.sliderRef = ref}>
                                {SLIDER_IMAGES.map((src, index) => (
                                    <div className={'img-wrapper'} key={'img-wrapper-' + index}>
                                        <img className={'transparent'} src={require('../assets/img/ios_transparent_600.png')}/>
                                        <div>
                                            <img className={'screenshot'} src={src} />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </section>
            </Screen>
        );
    }
}
/*
            <Screen logo={false} waitFor={false}>
                <h2>Collect things with your friends for joint activities.</h2>
                <p className={'app-description'}>
                    Oyeyku is an easy & fun app that allows you to collect items with others. Were you invited to a
                    baby shower, a wedding, or a trip with friends? Or perhaps you want to paint your house and
                    ask your friends for help? Oyeyku’s hands-on lists will help you collect everything needed,
                    regardless of the event. Create activities and add your friends. Tick off items as you collect
                    them and track your progress!
                </p>
                <div className={'boxes'}>
                    {this.boxes.map((box, index) => <InfoBox key={`key-${index}`}
                                                             title={box.title} description={box.description}
                                                                src={box.src}/>)}
                </div>
                <DownloadOyeyku />
            </Screen>
 */
export function DownloadOyeyku(props: any = {}) {
    return (
        <div className={'download-box'} {...props}>
            <span>Download now</span>
            <div style={{marginTop: 8}}>
                <a style={{display: 'inline-block', verticalAlign: 'middle'}} href={APPLE_STORE} target={'_blank'} title={'Download on the App Store'}>
                    <img height={40} style={{verticalAlign: 'middle'}} src={require('../assets/img/Download_on_the_App_Store_Badge.svg')}  alt={'App Store'}/>
                </a>
                <a style={{display: 'inline-block', verticalAlign: 'middle'}} href={GOOGLE_PLAY} target={'_blank'} title={'Get it on Google Play'}>
                    <img height={60} style={{verticalAlign: 'middle'}} src={require('../assets/img/google-play-badge.png')} alt={'Google Play'}/>
                </a>
            </div>
        </div>
    );
}
