import {isEmail, isEmpty, isUrl} from "./utils.helper";
import {INPUT_TEXT_MAX_LENGTH, TEXTAREA_MAX_LENGTH, URL_MAX_LENGTH} from "../Constants";
import i18n from '../i18n';
export class Validators {
    public static required() {
        function validate(text: string) {
            return !isEmpty(text);
        }
        return {validate: validate, id: 'required'};
    }
    public static regex(regex: RegExp) {
        function validate(text: string) {
            return regex.test(text);
        }
        return {validate: validate, id: 'regex'};
    }
    public static maxLength(max: number) {
        function validate(text: string) {
            if (!isEmpty(text)) {
                return text.length <= max;
            }
            return true;
        }
        return {validate: validate, id: 'maxLength', max: max};
    }
    public static minLength(min: number) {
        function validate(text: string) {
            if (isEmpty(text)) {
                return false;
            }
            return text.length >= min;
        }
        return {validate: validate, id: 'minLength'};
    }
    public static email(validOnEmpty?: boolean) {
        function validate(text: string) {
            return isEmail(text, validOnEmpty);
        }
        return {validate: validate, id: 'email'};
    }
    public static url() {
        function validate(text: string) {
            if (isEmpty(text)) {
                return true;
            }
            return isUrl(text);
        }
        return {validate: validate, id: 'url'};
    }
    public static rangeLength(min: number, max: number) {
        function validate(text: string) {
            if (isEmpty(text)) {
                return false;
            }
            return text.length >= min && text.length <= max;
        }
        return {validate: validate, id: 'rangeLength', max: max};
    }

    public static range(min: number, max: number) {
        function validate(num: number) {
            if (isEmpty(num)) {
                return false;
            }
            return num >= min && num <= max;
        }
        return {validate: validate, id: 'range', max: max};
    }
    public static max(max: number) {
        function validate(num: number) {
            if (isEmpty(num)) {
                return false;
            }
            return num <= max;
        }
        return {validate: validate, id: 'max', max: max};
    }
    public static min(min: number) {
        function validate(num: number) {
            if (isEmpty(num)) {
                return false;
            }
            return num >= min;
        }
        return {validate: validate, id: 'min'};
    }
    public static get forTitle() {
        return [
            [Validators.required(), i18n.t('validation_required')],
            [
                Validators.maxLength(INPUT_TEXT_MAX_LENGTH),
                i18n.t('validation_maxlength', {
                    length: INPUT_TEXT_MAX_LENGTH,
                }),
            ],
        ];
    }
    public static get forDescription() {
        return [
            [
                Validators.maxLength(TEXTAREA_MAX_LENGTH),
                i18n.t('validation_maxlength', {
                    length: TEXTAREA_MAX_LENGTH,
                }),
            ],
        ];
    }

    public static get forLink() {
        return [
            [Validators.url(), i18n.t('validation_invalid_url')],
            [
                Validators.maxLength(URL_MAX_LENGTH),
                i18n.t('validation_maxlength', {
                    length: URL_MAX_LENGTH,
                }),
            ],
        ];
    }
    public static get forEmail() {
        return [[Validators.email(), i18n.t('validation_invalid_email')]];
    }
    public static get forRequired() {
        return [
            [Validators.required(), i18n.t('validation_item_required')],
            [
                Validators.min(1),
                i18n.t('validation_item_required', {
                    min: 1,
                }),
            ],
        ];
    }
}
