import 'typeface-roboto';

import {Button, createMuiTheme} from '@material-ui/core';
import styled from "styled-components";

export const Colors = {
    text: '#5b5b5b',
    textMuted: '#B8B7B7',
    textStrong: '#222222',
    primary: '#009CB9', // '#40688F',
    primaryBright: '#00d4f1', // '#40688F',
    // primaryDark: '#334468',
    primaryDark: '#1D3041',
    appBarLightColorDisabled: 'rgba(41, 41, 41, 0.5)',
    appBarColorDisabled: 'rgba(255, 255, 255, 0.5)',
    appBarBgLight: '#F5F5F5',
    appBarBgLightDark: '#eaeaea',
    secondary: '#E89C0A', // '#c16536',
    secondaryDark: '#aa6a0a', // '#c16536',
    lightGray: '#E6E4E4',
    tabsBg: '#f5f5f5',
    lightBorder: '#d3d0cb',
    overlayerBg: 'rgba(3, 3, 3, 0.4)',
    selectedBg: 'rgba(100, 100, 226, 0.1)',
    grayBg: '#E6E4E4',
    success: '#2ED229',
    successText: '#04A016',
    warning: '#ffce00',
    danger: '#DC0303',
    link: '#558CC1',
    fb: '#4267b2',
    white: '#ffffff',
    black: '#222222',
    border: '#d3d0cb',
    cyclic: [
        '#1d721d',
        '#1d1f72',
        '#721f69',
        '#f52b0a',
        '#24b924',
        '#cf46d3',
        '#ff0919',
        '#205ea8',
        '#3aa9a2',
        '#f59c12',
        '#df0089',
        '#c0e23d',
        '#4f68ea',
        '#18d6d2',
        '#38bd2a',
        '#3dbdb4',
        '#bd1264',
        '#100757',
        '#f81213',
        '#16807b',
        '#e5d411',
        '#2bda8b',
    ],
    getColor: (severity: string) => {
        switch (severity) {
            case 'danger':
                return Colors.danger;
            case 'warning':
                return Colors.warning;
            case 'success':
                return Colors.success;
            default:
                return Colors.text;
        }
    },
};


export const theme = createMuiTheme({
    palette: {
        common: {
            black: Colors.black,
            white: Colors.white
        },
        background: {
            paper: Colors.grayBg,
            default: Colors.white
        },
        primary: {
            main: Colors.primary,
            dark: Colors.primaryDark,
            contrastText:  Colors.text
        },
        secondary: {
            main:  Colors.secondary,
            dark: Colors.secondaryDark,
            contrastText: Colors.white
        },
        error: {
            light: Colors.danger,
            main: Colors.danger,
            dark: Colors.danger,
        },
        text: {
            primary: Colors.textStrong,
            secondary: Colors.text,
            disabled: Colors.textMuted,
            hint: Colors.text
        },
        action: {
            selected: Colors.primary,
            hover: Colors.primaryDark,
            disabled: Colors.textMuted
        }
    },
    overrides: {
        // MuiFormControl-root
        MuiTabs: {
            indicator: {
                backgroundClip: 'content-box',
                border: '0 solid transparent',
                boxSizing: 'border-box'
            }
        },
        MuiInputBase: {
            root: {
                width: '100%'
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
                margin: '8px 0'
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: Colors.white
            },
            rounded: {
                backgroundColor: Colors.white
            }
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 700,
            lg: 701,
            xl: 1200
        }
    }
});
export const DarkButton = styled(Button)`
    align-self: flex-end !important;
    color: #ffffff !important;
    margin: 16px 0;
`;
export const LightButton = styled(Button)`
    align-self: flex-end !important;
    margin: 16px 0;
`;
