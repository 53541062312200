import HttpRequest from "./helper/HttpRequest";
import {ApiAuthParams, OyeykuException} from "./model";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import {observable} from "mobx";
import Account from "./Account";
import {errorHttpMessage, errorMessage} from "./helper/message.helper";
import {bind} from "decko";

export default class Backend {
    private account: Account;
    constructor(account: Account) {
        this.account = account;
    }
    @observable
    public pending = true;

    public recoverEmail(params: ApiAuthParams):  Promise<firebase.auth.ActionCodeInfo> {
        return HttpRequest.post('/auth/recoverEmail', params);
    }
    public async joinToBasket(invitationId: string | null): Promise<void> {
        if (invitationId) {
            this.pending = true;
            return HttpRequest.post('/baskets/join', {invitationId})
                .then(() => {
                    this.pending = false;
                    return Promise.resolve();
                })
                .catch(this.handleError);
        }
        return Promise.reject(OyeykuException.ERROR_CODE_OBJECT_NULL);
    }
    public loadInvitation(invitationId: string | null) {
        if (invitationId) {
            this.pending = true;
            return HttpRequest.get('/invitation/' + invitationId).then(result => {
                this.pending = false;
                return result;
            }).catch(this.handleError);
        }
        return Promise.reject(OyeykuException.ERROR_CODE_OBJECT_NULL)

    }
    @bind
    private handleError(error: any) {
        this.pending = false;
        if (error && typeof error.code === 'number') {
            return Promise.reject(errorHttpMessage(error.code))
        }
        if (typeof error === 'number') {
            return Promise.reject(errorHttpMessage(error))
        }
        return Promise.reject(errorMessage(error))
    }
}
