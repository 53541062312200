import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import './App.css';
import {theme} from "./theme";
import {Provider} from "mobx-react";
import {Redirect, Route, Router, Switch} from 'react-router';
import { createBrowserHistory } from 'history';
import Account from "./Account";
import Home from "./screens/Home";
import ApiAuth from "./screens/ApiAuth";
import NotFound from "./screens/NotFound";
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import * as Constants from "./Constants";
import Backend from "./Backend";
import Imprint from "./screens/Imprint";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import CookiePolicy from "./screens/CookiePolicy";
import JoinActivity from "./screens/JoinActivity";
import {invitationId} from "./helper/url.helper";
import Login from "./screens/Login";
import GlobalSnackbar from "./components/commons/GlobalSnackbar";
import {PrettySnackbarParams} from "./components/screen";
import TermsAndConditions from "./screens/TermsAndConditions";
const history = createBrowserHistory();
firebase.initializeApp(Constants.FIREBASE_CONFIG);
firebase.analytics();
const snackbarRef = React.createRef<GlobalSnackbar>();

export function showSnackbar(params: PrettySnackbarParams) {
    if (snackbarRef.current) {
        snackbarRef.current.show(params);
    }
}

export default class App extends React.Component {
  private account = new Account();
  private backend = new Backend(this.account);
  render() {
      // https://www.oyeyku.com/app/join/?i=iv1j8G81PNSYSXl0fqVN
    return (
        <ThemeProvider theme={theme}>
          <Provider account={this.account} backend={this.backend}>
              <Router history={history}>
                <Switch>
                  <Redirect from="/app/join" to={`/join/${invitationId()}`} />
                  <Route path="/join/:invitationId" component={JoinActivity} />
                  <Route exact path="/api/auth" component={ApiAuth} />
                  <Route exact path="/imprint" component={Imprint} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                  <Route exact path="/cookie-policy" component={CookiePolicy} />
                  <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                  <Route exact path="/" component={Home} />
                  <Route component={NotFound} />
                </Switch>
              </Router>
              <GlobalSnackbar ref={snackbarRef} />
          </Provider>
        </ThemeProvider>
    );
  }
}
