import {bind} from "decko";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import {autorun, computed, observable} from "mobx";

export default class Account {
    private readonly _unsubscribe: any

    @observable
    private _authUser: firebase.User | null = null;

    @observable
    private initialized = false;

    constructor() {
        this._unsubscribe = firebase.auth().onAuthStateChanged(this.onUserChange)
    }

    @computed
    public get isLoggedIn(): boolean {
        return !!this._authUser;
    }

    @computed
    public get authUser() {
        return this._authUser;
    }

    public get uid() {
        if(this._authUser) {
            return this._authUser.uid;
        }
        return null;
    }
    @bind
    protected onUserChange(authUser: firebase.User | null) {
        this._authUser = authUser;
        this.initialized = true;
    }

    public readonly ifInitialized = new Promise(resolve =>
        autorun(reaction => {
            if (this.initialized) {
                reaction.dispose();
                resolve();
            }

        }),
    );

    public unmount() {
        if (this._unsubscribe) {
            this._unsubscribe();
        }
    }

}
