import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import {observer} from "mobx-react";
import * as React from "react";
import {computed, observable} from "mobx";
import {bind} from "decko";
import {FormControl, IconButton} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const StyledTextField = styled(TextField)`
    width: 100%;
`;

interface PasswordProps {
    onChange: (text: string) => void;
    onBlur?: () => void;
    value: string;
    error: boolean;
    label: string;
    helperText?: string;
}

@observer
export default class PasswordInput extends React.PureComponent<PasswordProps> {

    @observable
    showPassword = false;

    @computed
    private get value() {
        return this.props.value;
    }

    @bind
    private handleChange(event: any) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value)
        }
    }
    @bind
    private handleClickShowPassword() {
        this.showPassword = !this.showPassword;
    }

    @bind
    private handleMouseDownPassword(event: any){
        event.preventDefault();
    }

    render() {
        return (
            <FormControl>
                <StyledTextField
                    type={this.showPassword ? 'text' : 'password'}
                    value={this.value}
                    label={this.props.label}
                    error={this.props.error}
                    helperText={this.props.helperText}
                    onChange={this.handleChange}
                    onBlur={this.props.onBlur ? this.props.onBlur : () => null}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                            >
                                {this.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        )
                    }}

                />
            </FormControl>
        );
    }
}
