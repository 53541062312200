import * as React from 'react';
import i18n from '../i18n';
import Screen from "../components/screen";
import {setMeta} from "../helper/utils.helper";
export default class TermsAndConditions extends React.PureComponent {
    public componentDidMount() {
        setMeta(i18n.t('title_page_tac'), i18n.t('desc_page_tac'));
    }
    render() {
        return (
            <Screen logo={true} cookie={false} waitFor={false}>
                <TermsText />
            </Screen>
        );
    }
}
export function TermsText(props: any) {
    return (
        <div>
            <h2>TERMS AND CONDITIONS</h2>
            <h4>Welcome to Oyeyku!</h4>
            These are the terms and conditions for:
            <ul>
                <li><strong>Oyeyku App (Android and iOS version – Available on Google Play and App store)</strong></li>
                <li><a href={'https://www.oyeyku.com'}>https://www.oyeyku.com</a></li>
            </ul>
            <p>
                By registering, downloading and using the application, you agree to be bound by these terms and conditions and our Privacy Policy. If you do not accept all of these terms, then you may not use the application and services. In these terms, "we", "us", "our" and Oyeyku refers to Oyeyku and "you" and "your" refers to you, the user of Oyeyku.
            </p>
            <p>
                The following terms and conditions apply to the website, application and services offered by Oyeyku.  This includes the mobile and tablet versions as well as any other version of Oyeyku accessible via desktop, mobile, tablet, social media or other devices.
            </p>
            <p>
                READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE DOWNLOADING, USING OR OBTAINING ANY PRODUCT, INFORMATION OR SERVICE FROM THE “OYEYKU” APPLICATION.
            </p>
            <h4>ACCEPTANCE OF TERMS</h4>
            <p>
                This agreement sets forth legally binding terms for your use of Oyeyku. By downloading and using the Oyeyku application, you agree to be bound by this agreement. If you do not accept the terms of this agreement, you should not download the application and discontinue use of the service immediately. We may modify this agreement from time to time, and such modification shall be effective upon its posting on Oyeyku. You agree to be bound by any modification to this terms and conditions when you use Oyeyku after any such modification is posted; it is therefore important that you review this agreement regularly.
            </p>
            <p>
                We may modify this agreement from time to time, and such modification shall be effective upon its posting on Oyeyku. You agree to be bound by any modification to this agreement when you use Oyeyku after any such modification is posted; it is therefore important that you review this agreement regularly.
            </p>
            <p>
                You represent and warrant that all registration information you submit is accurate and truthful; and that your use of the platform does not violate any applicable law or regulation. Company may, in its sole discretion, refuse to offer the platform to any entity and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the service and the platform is revoked in such jurisdictions.
            </p>
            <h4>ELIGIBLITY</h4>
            <p>
                You may use the application and services only if you can form a binding contract with Oyeyku, and only in compliance with these terms and all applicable local, state, national, and international laws, rules, and regulations.
            </p>
            <p>
                The use of this website and application for children under 13 years is prohibited and older children should be supervised and given appropriate guidance in their use of our platform and services. It is the responsibility of parents and legal guardians to determine whether any of the content and/or services are appropriate for their child.
            </p>
            <p>
                By downloading and using the application and services, you represent and warrant that you have the full right, power and authority to enter into this agreement and to fully perform all of your obligations hereunder. You further represent and warrant that you are under no legal disability or contractual restriction that prevents you from entering into this agreement.
            </p>
            <h4>OYEYKU</h4>
            <p>
                Oyeyku is an easy and fun app that allows you to collect items with others. Were you invited to a baby shower, a wedding, or a trip with friends? Or perhaps you want to paint your house and ask your friends for help? Oyeyku’s hands-on lists will help you collect everything needed, regardless of the event. Create activities and add your friends. Tick off items as you collect them and track your progress
            </p>
            <p>
                <strong>How it works?</strong>
            </p>
            <p>
                <strong>Create:</strong> Create a list of things you want to take with you on a trip, to buy as a gift or just to collect for another occasion or activity.
            </p>
            <p>
                <strong>Share:</strong> Share this list with your friends. Invite them to collect things together by sending them a link.
            </p>
            <p>
                <strong>Collect:</strong> Collect things with other participants for a joint activity. Share with others which items or how many you want to take.
            </p>
            <p>
                Users will have the possibility to create links to invite other people to join the activities created in the application.
            </p>
            <p>
                The Oyeyku application is available for iOS and Android and downloadable through Google Play and Apple Store. For more information about the app, please contact us, through our contact information, our support team will be attentive and available to answer your questions and concerns.
            </p>
            <h4>USERS</h4>
            <p>
                If you wish to register in the Oyeyku application, you must read this agreement and indicate your acceptance during the registration process. By registering on the application, you also accept the provisions of the privacy policy. In consideration of your use of the service, you represent that you are of legal age to form a binding contract and are not a person barred from receiving services under the applicable laws and jurisdiction. You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the registration form available on Oyeyku, (b) maintain and promptly update your registration data to keep it true, accurate, current and complete. If we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Oyeyku reserves the right to suspend or terminate your account and refuse any and all current or future use of the service (or any portion thereof) at any time and without any notification.
            </p>
            <h4>ACCOUNT AND SECURITY</h4>
            <p>
                If you register on Oyeyku, you will be required to choose a password and you may be asked for additional information regarding your account, such as your e-mail address and name. You can also register and login via Facebook or Apple ID. You are responsible for maintaining the confidentiality of your password and account information, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Oyeyku of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you log out from your account at the end of each session. You may never use another User’s account without prior authorization from Oyeyku. Oyeyku will not be liable for any loss or damage arising from your failure to comply with this Agreement.
            </p>
            <p>
                You may control your User profile and how you interact with the service by changing the settings in your account. By providing Oyeyku your email address you consent to our using the email address to send you service-related notices, including any notices required by law. We may also use your email address to send you other messages, such as changes to features of the Service and special offers. If you do not want to receive such email messages, you may opt out or change your preferences in your settings page. Opting out may prevent you from receiving email messages regarding updates, improvements, or offers.
            </p>
            <h4>ACCOUNT DELETION AND TERMINATION</h4>
            <p>
                The users may terminate their accounts any time, for any reason, by following the instructions on Oyeyku. That termination will only result in the deletion of the account and the deletion of all the personal data granted to Oyeyku.
            </p>
            <p>
                Oyeyku reserves the right to terminate your account or your access immediately, with or without notice to you, and without liability to you, if Oyeyku believes that you have breached any of these terms, furnished Oyeyku with false or misleading information, or interfered with use of the website or the service by others.
            </p>
            <h4>LICENSE TO USE THE PLATFORM</h4>
            <p>
                Oyeyku gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by Oyeyku as part of the services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the services as provided by Oyeyku, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission.
            </p>
            <p>
                The user agrees not to use the platform and the services negligently, for fraudulent purposes or in an unlawful manner. Likewise, the user agrees not to partake in any conduct or action that could damage the image, interests or rights of the Oyeyku application or third parties.
            </p>
            <h4>DISCLAIMER</h4>
            <p>
                Oyeyku does not represent the content posted by users on the application. Oyeyku does not accept any claims of any kind for the content published in the application by users.
            </p>
            <p>
                Oyeyku is not responsible for any damage to the physical or moral integrity of people, such as injuries, death or any other moral damage such as threats, insults and slander that could fall on a natural person, as a result of the communications established in the Oyeyku application. The communications and relationships established between users as a result of any connection within the Oyeyku application is the sole and exclusive responsibility of the users.
            </p>
            <p>
                In the event that one or more users or any third party initiate any type of claim or legal action against another or other users, each and every one of the users involved in those claims or actions exempt Oyeyku from any responsibility.
            </p>
            <h4>RESALE OF SERVICES</h4>
            <p>
                Users may not resell any portion of the content or the functionality of the platform, to any other party without prior written permission from Oyeyku, which may be granted or withheld in Oyeyku sole discretion.
            </p>
            <h4>SECURITY COMPONENTS</h4>
            <p>
                You understand that Oyeyku and software embodied within Oyeyku may include security components that permit digital materials to be protected, and that use of these materials is subject to usage rules set by Oyeyku and/or content providers who provide content to Oyeyku platform. You may not attempt to override, disable, circumvent or otherwise interfere with any such security components and usage rules embedded into Oyeyku.
            </p>
            <h4>COPYRIGHT</h4>
            <p>
                All materials on Oyeyku, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, artwork, software and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by Oyeyku or by third parties that have licensed or otherwise provided their material to the application and Website. You acknowledge and agree that all Materials on Oyeyku are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without Oyeyku prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize Oyeyku or any part of the material for any purpose other than its intended purposes is strictly prohibited.
            </p>
            <h4>COPYRIGHT COMPLAINTS </h4>
            <p>
                Oyeyku respects the intellectual property of others, and expects users to do the same. If you believe, in good faith, that any materials provided on or in connection with the Oyeyku website and application infringe upon your copyright or other intellectual property right, please send the following information to:
            </p>
            <ol>
                <li>dentification of the intellectual property right that is allegedly infringed. All relevant registration numbers, or a statement concerning the ownership of the work, should be included.</li>
                <li>A statement specifically identifying the location of the infringing material, with enough detail that Oyeyku may find it on the Oyeyku website and application.  Please note: it is not sufficient to merely provide a top-level URL.</li>
                <li>Your name, address, telephone number and e-mail address.</li>
                <li>A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the owner of the rights, or its agents, or by law.</li>
                <li>A statement by you, made under penalty of perjury, that the information in your notice is accurate, and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
                <li>An electronic or physical signature of the owner of the copyright or the person authorized to act on behalf of the owner of the copyright interest.</li>
            </ol>
            <h4>USER CONTENT</h4>
            <p>
                Certain features of the application may permit users to upload content, which may be comprised of messages, images, text, and others and to publish that user content on the application. You retain any Copyright that you may hold in the user content that you post to the application. Nevertheless, we need certain permission from you in order to publish the content. Oyeyku is not responsible for the accuracy, safety or legality of user content published in the application. Users are solely and exclusively responsible for their content and the consequences of publishing their content.
            </p>
            <h4>LICENSE GRANT TO OYEYKU</h4>
            <p>
                By providing user content through the application, you grant Oyeyku a worldwide, non-exclusive, royalty-free, fully paid (with the right to sublicense) right and license to host, store, transfer, display, perform, reproduce, modify, publish and share with other authorized users your user content on the application for the sole purpose of achieving optimal performance of the application.
            </p>
            <h4>USER CONTENT REPRESENTATIONS AND WARRANTIES</h4>
            <p>Oyeyku disclaims any and all liability in connection with User Content. You are solely responsible for your user content and the consequences of providing user content via the service. By providing user content via the Service, you affirm, represent, and warrant that:
            </p>
            <p>
                a) You are the creator and owner of the User Content, or have the necessary licenses, rights, consents, and permissions to authorize Oyeyku and users of the service to use and distribute your user content as necessary to exercise the licenses granted by you in this section, in the manner contemplated by  Oyeyku, the Service, and these Terms.
            </p>
            <p>
                b) Your User content, and the use of your user content as contemplated by these Terms, does not and will not: (i) infringe, violate, or misappropriate any third party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or (iii) cause  Oyeyku to violate any law or regulation.
            </p>
            <p>
                c) Your user content could not be deemed by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.
            </p>
            <p>
                d) Your user content does not and will not contain Hateful Content, a Threat of Physical Harm, or Harassment.
            </p>
            <h4>USER CONTENT DISCLAIMER</h4>
            <p>
                We are under no obligation to edit or control user content that you or other users post or publish, and will not be in any way responsible or liable for User Content. Oyeyku may, however, at any time and without prior notice, screen, remove, edit, or block any user content that in our sole judgment violates these Terms or is otherwise objectionable. You understand that when using the service, you will be exposed to user content from a variety of sources and acknowledge that user content may be inaccurate, offensive, indecent, or objectionable. You agree to waive, and do waive, any legal or equitable right or remedy you have or may have against Oyeyku with respect to user content. If notified by a user or content owner that user content allegedly does not conform to these Terms, we may investigate the allegation and determine in our sole discretion whether to remove the user content, which we reserve the right to do at any time and without notice. For clarity, Oyeyku does not permit copyright-infringing activities on the Service.
            </p>
            <h4>CONTENT REVIEW</h4>
            <p>
                Oyeyku does not control and does not have any obligation to monitor: (a) User Content; (ii) any content made available by third parties; or (iii) the use of the services by its users. You acknowledge and agree that Oyeyku reserves the right to, and may from time to time, monitor any and all information transmitted or received through the service for operational and other purposes. If at any time Oyeyku chooses to monitor the content, Oyeyku still assumes no responsibility or liability for content or any loss or damage incurred as a result of the use of content. During monitoring, information may be examined, recorded, copied, and used in accordance with our Privacy Policy.
            </p>
            <h4>PERSONAL DATA</h4>
            <p>
                Any personal information you post on or otherwise submit in connection with the services and the use of the application, will be used in accordance with our Privacy Policy. When you use the Oyeyku application, you agree for us to collect and store your personal information. The personal information collected is also used to check the correctness of identification and do necessary checks. In giving the information you also agree for us to message or communicate with you unless you tell us not to. Please refer to our Privacy Policy.
            </p>
            <h4>NON-COMMERCIAL USE</h4>
            <p>
                The service may not be used in connection with any commercial purposes, except as specifically approved by Oyeyku. Unauthorized framing of or linking to any of Oyeyku is prohibited. Commercial advertisements, affiliate links, and other forms of content without notice and may result in termination of the account.
            </p>
            <h4>THIRD PARTIES</h4>
            <p>
                Oyeyku contains content of third-party licensors to Oyeyku, which is protected by copyright, trademark, patent, trade secret and other laws. Oyeyku owns and retains all rights, title and interest in the content. Oyeyku hereby grants to you a limited, revocable, non-sublicensable license to stream and/or view the content and any third party content located on or available through Oyeyku or services (excluding any software code therein as set forth above) solely for your personal, non-commercial use in connection with viewing Oyeyku and using the services.
                <br/>
                Any dealings with third parties included within or on Oyeyku involving the delivery of and payment for goods and services, or any other terms, conditions, warranties, or representations associated with such third parties, are solely between you and that third party. Oyeyku is neither responsible nor liable for any part of such dealings or promotions. Any reference to any third party or the products or services of any third party on Oyeyku does not constitute an endorsement or recommendation of such third party or the product or services of such third party by Oyeyku or any of its employees, officers, independent contractors, agents or other representatives. Any reference to any third party on Oyeyku is provided to you for informational purposes only. Oyeyku encourages you to conduct your own research and due diligence regarding such third parties and their products and services. While Oyeyku works to ensure the information on Oyeyku is current and accurate.
            </p>
            <h4>ACCOUNT DELETION AND TERMINATION</h4>
            <p>
                The users may terminate their accounts any time, for any reason, by following the instructions on Oyeyku. That termination will only result in the deletion of the account and the deletion of all the personal data granted to Oyeyku.
            </p>
            <p>
                Oyeyku reserves the right to terminate your account or your access immediately, with or without notice to you, and without liability to you, if Oyeyku believes that you have breached any of the terms of this agreement, furnished Oyeyku with false or misleading information, or interfered with use of the application, website or the service by others.
            </p>
            <h4>PROHIBITED ACTIVITIES</h4>
            <p>
                The content and information available on the website and application (including, but not limited to, data, information, text, music, sound, photos, graphics, video, maps, icons or other material), as well as the infrastructure used to provide such Content and information, is proprietary to Oyeyku or licensed to the Oyeyku by third parties. For all content other than your content, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software or services obtained from or through the website. Additionally, you agree not to:
            </p>
            <ol>
                <li>Use the services or content for any commercial purpose, outside the scope of those commercial purposes explicitly permitted under this agreement and related guidelines as made available by Oyeyku.</li>
                <li>Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any Content of the services, including but not limited to, using any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.</li>
                <li>Violate the restrictions in any robot exclusion headers on the services or bypass or circumvent other measures employed to prevent or limit access to the services.</li>
                <li>Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.</li>
                <li>Deep-link to any portion of the services for any purpose without our express written permission.</li>
                <li>"Frame", "mirror" or otherwise incorporate any part of the services into any other websites or service without our prior written authorization.</li>
                <li>Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Oyeyku in connection with the services.</li>
                <li>Circumvent, disable or otherwise interfere with security-related features of the services or features that prevent or restrict use or copying of any Content.</li>
                <li>Download any Content unless it’s expressly made available for download by Oyeyku.</li>
            </ol>
            <h4>DISCLAIMER OF WARRANTIES</h4>
            <p>
                Oyeyku will provide its services with reasonable skill and care but does not give any guarantees, warranties or representations in respect of any other person's services.
                Because of the nature of the Internet Oyeyku provides and maintains the Website and application on an "as is", "as available" basis and makes no promise that use of the website and application will be uninterrupted or entirely error free. We are not responsible to you if we are unable to provide our Internet services for any reason beyond our control.
            </p>
            <p>
                Our Website and application may from time to time contain links to other web sites which are not under the control of and are not maintained by us. These links are provided for your convenience only and we are not responsible for the content of those sites.
            </p>
            <p>
                Except as provided above we can give no other warranties, conditions or other terms, express or implied, statutory or otherwise and all such terms are hereby excluded to the maximum extent permitted by law.
            </p>
            <p>
                You will be responsible for any breach of these terms by you and if you use the application in breach of these terms you will be liable to and will reimburse Oyeyku for any loss or damage caused as a result.
            </p>
            <p>
                Oyeyku will not be liable in any amount for failure to perform any obligation under this agreement if such failure is caused by the occurrence of any unforeseen event beyond its reasonable control including without limitation Internet outages, communications outages, fire, flood, war or act of God.
            </p>
            <p>
                These terms do not affect your statutory rights as a consumer which are available to you.
            </p>
            <p>
                Subject as aforesaid, to the maximum extent permitted by law, Oyeyku excludes liability for any loss or damage of any kind howsoever arising, including without limitation any direct, indirect or consequential loss whether or not such arises out of any problem you notify to Oyeyku and  Oyeyku shall have no liability to pay any money by way of compensation, including without limitation all liability in relation to:
            </p>
            <ul>
                <li>Any incorrect or inaccurate information on Oyeyku.</li>
                <li>Any interruptions to or delays in updating Oyeyku.</li>
                <li>The infringement by any person of any Intellectual Property Rights of any third party caused by their use of the application or any product or service purchased through the application.</li>
                <li>Any loss or damage resulting from your use or the inability to use the application or resulting from unauthorized access to, or alteration of your transmissions or data in circumstances which are beyond our control.</li>
                <li>Any loss of profit, wasted expenditure, corruption or destruction of data or any other loss which does not directly result from something we have done wrong.</li>
                <li>Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data or other property caused by persons accessing or using content from The Website and application, or from transmissions via emails or attachments received from Oyeyku.</li>
                <li>All representations, warranties, conditions and other terms which but for this notice would have effect.</li>
            </ul>
            <h4>ELECTRONIC COMMUNICATIONS</h4>
            <p>
                No responsibility will be accepted by Oyeyku for failed, partial or garbled computer transmissions, for any computer, telephone, cable, network, electronic or internet hardware or software malfunctions, failures, connections, availability, for the acts or omissions of any Cleaner User, internet accessibility or availability or for traffic congestion or unauthorized human act, including any errors or mistakes.
            </p>
            <h4>INDEMNIFICATION</h4>
            <p>
                You agree to defend and indemnify Oyeyku from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:
            </p>
            <ul>
                <li>Your breach of this agreement or the documents referenced herein.</li>
                <li>Your violation of any law or the rights of a third party.</li>
                <li>Download the Oyeyku application.</li>
                <li>Your use of the Oyeyku application and services.</li>
            </ul>
            <h4>CHANGES AND TERMINATION</h4>
            <p>
                We may change the application, website and these terms at any time, in our sole discretion and without notice to you. You are responsible for remaining knowledgeable about these terms. Your continued use of the site constitutes your acceptance of any changes to these Terms and any changes will supersede all previous versions of the terms. Unless otherwise specified herein, all changes to these Terms apply to all users take effect. Furthermore, we may terminate this agreement with you under these Terms at any time by notifying you in writing (including by email) or without any warning.
            </p>
            <h4>ASSIGNMENT</h4>
            <p>
                This agreement and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Oyeyku without restriction.
            </p>
            <h4>INTEGRATION CLAUSE</h4>
            <p>
                This agreement together with the Privacy Policy and any other legal notices published by Oyeyku, shall constitute the entire agreement between you and Oyeyku concerning and governs your use of the applicationlication, website and the service.
            </p>
            <h4>DISPUTES</h4>
            <p>
                You agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Oyeyku website and application, will be settled by binding arbitration between you and  Oyeyku, except that each party retains the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights. You acknowledge and agree that you and Oyeyku are each waiving the right to a trial by jury or to participate as a plaintiff or class in any purported class action or representative proceeding. Further, unless both you and Oyeyku otherwise agree in writing, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of any class or representative proceeding.
            </p>
            <h4>HEADINGS</h4>
            <p>Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. These terms set forth the entire understanding and agreement between us with respect to the subject matter therein.</p>
            <h4>FINAL PROVISIONS</h4>
            <p>
                These Terms are governed by the law of Germany and the European Union. Use of Oyeyku application and website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this paragraph.
            </p>
            <p>
                Our performance of these Terms is subject to existing laws and legal process, and nothing contained in these Terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our Platform or information provided to or gathered by us with respect to such use.
            </p>
            <p>
                If any part of these Terms is found to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay in enforcing any provision of these Terms at any time does not waive our right to enforce the same or any other provision(s) hereof in the future.
            </p>
            <p>
                Any rights not expressly granted herein are reserved.
            </p>
        </div>
    );
}
