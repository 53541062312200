import i18n from 'i18n-js';
import en from './en';
const translationGetters = {
    en: () => en,
};
const setI18nConfig = () => {
    // fallback if no available language fits
    // const fallback = {languageTag: 'en', isRTL: false};
    const {languageTag, isRTL} = {languageTag: 'en', isRTL: false}; // RNLocalize.findBestAvailableLanguage(Object.keys(translationGetters)) || fallback;
    i18n.translations = {[languageTag]: translationGetters[languageTag]()};
    i18n.locale = languageTag;
};
setI18nConfig();

export default i18n;
