import {inject, observer} from "mobx-react";
import * as React from "react";
import {ApiAuthParams, InjectedBackendProps} from "../../model";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import {CircularProgress} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {computed, observable} from "mobx";
import {oc} from "ts-optchain";
import {Colors} from "../../theme";

type Props = { params: ApiAuthParams};

@inject('backend')
@observer
export default class RecoverEmail extends React.PureComponent<Props> {
    @observable
    private status: 'pending' | 'success' | 'failure';

    @observable
    private actionCodeInfo: firebase.auth.ActionCodeInfo | null = null;

    constructor(props: Props) {
        super(props);
        this.status = 'pending';
    }
    private get injected() {
        return this.props as Props & InjectedBackendProps;
    }
    private get actionCode(): string {
        return this.props.params.oobCode;
    }
    @computed
    private get email(): string {
        return oc(this.actionCodeInfo).data.email('');
    }
    public componentDidMount() {
        firebase.auth().checkActionCode(this.actionCode)
            .then(info => {
                this.actionCodeInfo = info;
                return firebase.auth().applyActionCode(this.actionCode);
            })
            .then(() => {
                firebase.analytics().logEvent('recover_email');
                this.status = 'success';
            })
            .catch(error => {
                this.status = 'failure';
            });
    }
    private renderContent() {
        if (this.status === 'pending') {
            return <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div>;
        }
        if (this.status === 'success') {
            return (
                <>
                    <h3>Updated email address</h3>
                    <p>Your sign-in email address has been changed back to <span style={{color: Colors.secondary}}>{this.email}</span>.</p>
                    <p>If you didn’t ask to change your sign-in email, it’s possible someone is trying to access your account and you should change your password right away.</p>
                </>
            );
        }
        if (this.status === 'failure') {
            return (
                <Alert severity="error">
                    <AlertTitle>Unable to update your email address</AlertTitle>
                    <p>There was a problem changing your sign-in email back.</p>
                </Alert>
            );
            //    <!-- <p>If you try again and still can’t reset your email, try asking your administrator for help.</p> -->
        }
        return null;
    }

    render() {
        return (
            <div>{this.renderContent()}</div>
        );
    }
}
