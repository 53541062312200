//ENGLISH
const texts = {
    app_name: 'Oyeyku',
    no_internet_alert: 'There is no internet connection',
    app_version: 'Version: {{version}}',
    about_oyeyku_desc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    activities_empty_list: 'There are no activities yet...',
    activities: 'Activities',
    join: 'Join',
    accept: 'Accept',
    accept_activity_desc: 'Join the activity and start collecting items with your friends!',
    accept_sharelink_info: 'To accept the invitation and join the activity, log into your Oyeyku account or create a new user account.',
    decline: 'Decline',
    deleted_user: 'Deleted user',
    email: 'Email',
    ok: 'Ok',
    email_optional: 'Email (optional)',
    message_sent: 'Your message has been sent.',
    change: 'Change',
    create_new_activity: 'Create new activity',
    item: 'Item',
    item_created: 'Item has been created',
    item_updated: 'Item has been updated',
    name: 'Name',
    yes: 'Yes',
    user: 'User',
    help: 'Help',
    no: 'No',
    private_policy: 'Privacy policy',
    imprint: 'Imprint & privacy policy',
    // terms_of_use: 'Terms & Condictions',
    contact: 'Contact',
    send_feedback: 'Send feedback',
    copied_to_clipboard: 'Copied to clipboard',
    can_not_open_url: "Can't open URL",
    error: 'Error',
    logout: 'Log out',
    loading: 'Loading...',
    settings: 'Settings',
    push_notification: 'Push Notifications',
    settingsBasketComplete: 'The activity has been completed',
    settingsNewComment: 'A new comment has been added',
    settingsNewUser: 'A new user joined the basket',
    settingsNewItem: 'A new item has been added',
    activity: 'Activity',
    required: 'Required',
    required_items: 'Amount of items to be collected',
    description: 'Description',
    empty_state_items: 'You have no items yet',
    empty_state_items_create: 'Create a new item',
    link: 'Link',
    lock: 'Lock activity',
    unlock: 'Unlock activity',
    get_premium: 'Get premium',
    blocked_users: 'Blocked users',
    password: 'Password',
    error_401: 'Your trying to access an area that needs authentication',
    enter_password: 'Enter your password',
    enter_password_desc: 'The operation requires re-authentication.',
    enter_password_email_desc: 'Updating your email address requires re-authentication.',
    enter_password_delete_desc: 'To confirm account deletion, enter your password.',
    you_account_has_been_deleted: 'You account has been deleted.',
    confirm_password: 'Confirm password',
    forgot_password: 'Forgot password?',
    old_password: 'Old password',
    change_password: 'Change password',
    password_changed: 'Password has been changed.',
    reset_password_title: 'Reset your password',
    reset_password_desc: 'An email with the activation link will be sent.',
    reset_password_sent: 'An email to reset your password has been sent.',
    create_account: 'CREATE A NEW ACCOUNT',
    new_account: 'Create an account',
    update_account_info: 'Update account',
    link_fb_provider: 'Link Facebook with your account',
    login_button: 'Log in',
    register_button: 'Create account',
    display_name: 'Display name',
    error_uknow: 'An unknown error has occurred.',
    admin: 'Administrator',
    create: 'Create',
    send: 'Send',
    cancel: 'Cancel',
    choose: 'Choose',
    continue: 'Continue',
    skip: 'Skip',
    share: 'Share',
    share_message: 'Join the activity, by clicking on the following link: {{link}}',
    or: 'or',
    title: 'Title',
    and: 'and',
    add: 'Add',
    update: 'Update',
    see_all_user: 'see all user',
    share_link_switcher: 'Activate the shared link',
    share_link_switcher_desc: 'If the shared link is set as activated, users can join the activity using the link they received. When you deactivate the link, new users can\'t join.',
    error_invitation: 'An error has occurred. The link cannot be found.',
    error_invitation_disabled: 'You cannot join the activity. This can happen for the following reasons:',
    delete: 'Delete',
    delete_account: 'Delete account',
    remove: 'Remove',
    documents_removed: '{{count}} document(s) have been removed.',
    document_updated: '{{what}} has been updated.',
    activity_updated_complete_lock: 'The activity has been locked and marked as completed.',
    activity_updated_locked: 'The activity has been locked.',
    activity_updated_unlocked: 'The activity has been unlocked.',
    documents_will_be_removed: '{{count}} document(s) will be removed.',
    edit: 'Edit',
    edit_profile: 'Edit profile',
    profile_updated: 'Your profile has been updated.',
    linkTo: 'Link to {{name}}',
    to_leave_out: 'To leave out',
    contributors: 'Contributors ({{count}})',
    accept_policy_before: 'I accept the ',
    register_accept_policy_error: 'Privacy Policy must be accepted',
    see_all: 'See all',
    see_all_with_count: 'See all ({{count}})',
    copy: 'Copy',
    copy_link: 'Copy link',
    retry_button: 'Retry',
    user_not_logged_in: 'You are not logged in',
    item_doesnt_exists: 'Item can not be find',
    no_db_connection: 'Problem with database connection',
    profile_picture: 'Profile picture',
    profile: 'Profile',
    terms_of_use: 'Terms of Use',
    image: 'Image',
    processing_assets: 'Processing assets', // TODO
    permission_denied: "You don't have permission to see the data.",
    plural: {
        user_involved: {
            one: 'user involved',
            other: '{{count}} users involved',
            more: '{{count}} users involved',
            zero: '0 users involved',
        },
        items: {
            one: '1 item',
            zero: '{{count}} items',
            more: '{{count}} items',
            other: '{{count}} items',
        },
        comments: {
            one: '1 comment',
            zero: '{{count}} comments',
            more: '{{count}} comments',
            other: '{{count}} comments',
        },
    },
    participants: {
        one: '1 participant',
        zero: 'No participants',
        more: '{{count}} participants',
        other: '{{count}} participants',
    },
    activities_removed: {
        one: '1 activity has been removed',
        zero: 'No activities have been removed',
        more: '{{count}} activities have been removed',
        other: '{{count}} activities have been removed',
    },
    items_removed: {
        one: '1 item has been removed',
        zero: 'No items have been removed',
        more: '{{count}} items have been removed',
        other: '{{count}} items have been removed',
    },
    user_removed: 'The user has been removed',
    /*
    '%n comments':[
        [0, 0, "%n comments"],
        [1, 1, "%n comment"],
        [2, null, "%n comments"]
    ],*/
    timeago: {
        few_seconds: 'a few seconds ago',
        minute: 'a minute ago',
        minutes: '{{minutes}} minutes ago',
        hour: 'an hour ago',
        hours: '{{hours}} hours ago',
        day: 'a day ago',
        days: '{{days}} days ago',
        month: 'a month ago',
        months: '{{months}} months ago',
        year: 'a year ago',
        years: '{{years}} years ago',
    },
    validation_required: 'The field cannot be empty.',
    validation_no_whitespace: 'The text cannot contain empty spaces.',
    validation_passowrd_no_whitespace: 'The password cannot contain empty spaces.',
    validation_item_required: 'Amount must be > 0',
    validation_maxlength: 'The text cannot be longer than {{length}} characters.',
    validation_min: 'Value must be greater than or equal to {{min}}.',
    confirm_password_doesnt_match: "The password doesn't match the confirmed password.",
    validation_max: 'Value must be less than or equal to {{max}}.',
    validation_invalid_email: 'The email address format is not valid.',
    validation_invalid_url: 'The URL is invalid.',
    validation_item_count: 'Item count must be greater then {{min}} and less or equal then {{max}}',
    screen_activities_title: 'Activities',
    screen_accept_activity_title: 'Join the activity',
    screen_contact_desc: 'Send us a message or request a new feature.',
    screen_contact_title: 'Contact',
    screen_account_title: 'Account',
    screen_item_title: 'Item',
    screen_new_item_title: 'New item',
    screen_about_title: 'About',
    screen_help_title: 'Help',
    screen_premium_title: 'Get premium',
    screen_feedback_title: 'Feedback',
    screen_privacy_title: 'Private Policy',
    screen_imprint_title: 'Imprint',
    item_collected_text: 'The item has been collected',
    item_not_collected_text: 'The item has not been collected yet',
    item_collected_text_all_0: 'All',
    item_collected_text_all_1: 'Items have been collected',
    item_collected_text_from_0: 'out of',
    item_collected_text_from_1: 'items have already been collected.',
    item_collected_text_0: 'No contributor.',
    item_collected_text_1: 'Item collected by:',
    item_has_been_created: 'Item has been successfully created.',
    item_has_been_updated: 'Item has been successfully updated.',
    are_you_sure: 'Are you sure?',
    alert_remove_hard_basket_msg: 'This activity, all items and participants will be removed.',
    alert_remove_participant_msg: 'The participant will be removed.',
    alert_delete_account_msg: 'Your account will be deleted.',
    alert_logout_msg: 'You will be logged out.',
    alert_remove_the_basket_msg: 'This activity will be removed from your list.',
    alert_remove_basket_msg: {
        one: '1 activity will be removed.',
        zero: 'No activities will be removed.',
        more: '{{count}} activities will be removed.',
        other: '{{count}} activities will be removed.',
    },
    alert_remove_items_msg: {
        one: '1 item  will be removed.',
        zero: 'No items will be removed.',
        more: '{{count}} items will be removed.',
        other: '{{count}} items will be removed.',
    },
    activity_has_been_deleted: 'Activity has been deleted.',
    take_it: 'Take it',
    drop_it: 'Drop',
    take: 'Take',
    closed_by: 'Locked by {{name}}',
    empty_screen_text: 'Empty page',
    your_amount: 'Your amount',
    user_has_been_blocked: 'User has been blocked.',
    user_has_been_blocked_info: 'You have been blocked and you can not change the activity.',
    user_has_been_unblocked: 'User has been unblocked.',
    title_page: 'Oyeyku - Collect things with your friends for joint activities',
    desc_page: 'Oyeyku lets you collect things with your friends for joint activities. Oyeyku’s hands-on lists will help you collect everything needed, regardless of the event. Create activities, add your friends, tick off items and track your progress.',
    title_page_404: 'Oyeyku - The page you are looking for is not found',
    desc_page_404: 'The page you are looking for is not found',
    title_page_join: 'Oyeyku - Join the activity',
    desc_page_join: 'Join the activity and start collecting items with your friends',
    title_page_api: 'Oyeyku - API service',
    desc_page_api: 'API service',
    title_page_imprint: 'Oyeyku - Imprint',
    desc_page_imprint: 'Imprint',
    title_page_login: 'Oyeyku - Login',
    desc_page_login: 'Login',
    title_page_privacy_policy: 'Oyeyku - Privacy Policy',
    desc_page_privacy_policy: 'Privacy Policy',
    title_page_cookie_policy: 'Oyeyku - Cookie Policy',
    desc_page_cookie_policy: 'Cookie Policy',
    title_page_tac: 'Oyeyku - Terms and Conditions',
    desc_page_tac: 'Terms and Conditions',
};
const menu = {
    new_activity_gift: 'Gifts',
    new_activity_trip: 'Trip',
    new_activity_other: 'Other activity',
    //  "celebrate": "Celebrate (wedding, birthday, etc..)",
    //   "gift": "Gifts",
    //   "trip": "Trip",
    //   "other_activity": "Other activity",
    //   "view": "View",
    menu_select_item: 'Select items',
    menu_select: 'Select',
    menu_disable_item: 'Close',
    menu_enable_item: 'Open',
    menu_exit_basket: 'Exit this activity',
    menu_add_users: 'Add users',
    menu_remove_user: 'Remove user',
    menu_reorder: 'Reorder',
    menu_reorder_items: 'Reorder items',
    menu_sort: 'Sort',
    //   'edit': 'Edit',
    //   'delete': 'Delete',
    menu_block: 'Block user',
    menu_blocked_users: 'Blocked users',
    menu_unblock: 'Unblock user',
    //   'exit_basket': 'Exit Basket',
    menu_take: 'Take item',
    menu_from_camera: 'Use camera',
    menu_from_gallery: 'Choose from gallery',
    menu_make_admin: 'Set as activity admin',
    menu_dismiss_admin: 'Dismiss as admin',
    menu_basket_done: 'Mark as done',
    menu_basket_undone: 'Mark as undone',
    menu_drop: 'Drop item',
};
const account = {
    account_title: 'Account',
    account_link_with_fb: 'Link Facebook with your account',
    login_password_wrong: 'The password you entered is incorrect.',
};
const login = {
    login_email_password_wrong: 'The username or password you entered is incorrect.',
    login_title: 'Login to Oyeyku',
    login_agreement: 'By signing up, you agree to our Terms of Use and Privacy Policy.',
    login_with_fb: 'Continue with Facebook',
    login_with_apple: 'Continue with Apple ID',
    login_with_fb_addition: 'We will never post anything on Facebook',
    login_user_disabled: 'The user has been disabled.',
    login_user_cancelled: 'User cancelled request.',
    login_unknow_error: 'Unknown error has occurred during a login.',
};
const activity = {
    activity_empty_basket: 'The activity could not be loaded.',
    activity_new_label_photo: 'Photo',
    activity_items_label: 'What we need to take:',
    activity_items_label_gift: 'What we need to buy ({{size}}):',
    activity_items_label_trip: 'What we need to take ({{size}}):',
    activity_items_label_other: 'What we need to collect ({{size}}):',
    activity_items_label_other_ext: 'What we need to collect:',
    activity_excluded_items_label: 'To leave out ({{size}}):',
    activity_new_adding_items_allowed: 'Allow every user to add items',
    activity_new_adding_gifts_allowed: 'Allow every user to add items',
    activity_new_trip_title: 'New trip',
    activity_new_gift_title: 'New gift list',
    activity_new_other_title: 'New activity',
    activity_edit_trip_title: 'Edit trip',
    activity_edit_gift_title: 'Edit gift list',
    activity_edit_other_title: 'Edit activity',
    activity_tab_basket: 'List',
    activity_tab_users: 'Users',
    activity_tab_chat: 'Comments',
    activity_type_0: 'Checklist',
    activity_type_0_desc: 'Checklista,  w ktorej kazdy moze zaznaczyc jedna rzecz, ktora chce zabrac.',
    activity_type_1: 'Sharedlist',
    activity_type_1_desc:
        'Lista w ktorej dodatkowo mozna dodac ilosc zbieranej rzeczy (np: 10 jablek) i podzielic ta ilosc na uzytkownikow. ',
    activity_cat_gift_desc:
        'Gift Users can collect private, mandatory and in common items. Private items are not visible for other participants.  Mandatory items must be collected by every user. You can define items that schoult be not taken with as well.',
    activity_cat_trip_desc:
        'Trip Users can collect private, mandatory and in common items. Private items are not visible for other participants.  Mandatory items must be collected by every user. You can define items that schoult be not taken with as well.',
    activity_cat_other_simple_desc:
        'Simple Users can collect private, mandatory and in common items. Private items are not visible for other participants.  Mandatory items must be collected by every user. You can define items that schoult be not taken with as well.',
    activity_cat_other_ext_desc:
        'Ext Users can collect private, mandatory and in common items. Private items are not visible for other participants.  Mandatory items must be collected by every user. You can define items that schoult be not taken with as well.',
    activity_item_allowed_desc: 'Every user can add an item to the list.',
    activity_item_not_allowed_desc: 'Only admins can add items to the list.',
    activity_item_name: 'Item name',
    activity_item_description: 'Item description',
    activity_item_link: 'Item link (e.g. amazon article)',
    activity_item_exclude: 'Exclude this item from the list',
    activity_item_type_0: 'In common',
    activity_item_type_0_desc: "Common items are very useful for everyone and are displayed in the tab 'In common'.",
    activity_item_type_1: 'Mandatory',
    activity_item_type_1_desc: 'The number of items is as many as there are users and will change automatically if the user joins or leaves the activity.',
    activity_item_type_2: 'Private',
    activity_item_type_2_desc:
        "Private stuff is only visible to you and will not appear in the list for others. Tab 'Private'",
    activity_item_type_3_desc: 'Exluded items will be not collected.',
    activity_item_type_gift_desc:
        "Gift desc stuff is only visible to you and will not appear in the list for others. Tab 'Private'",
    activity_item_type_trip_desc:
        "Trip desc stuff is only visible to you and will not appear in the list for others. Tab 'Private'",
};
const units = {
    liters: 'liters',
    l: 'l',
    milliliter: 'milliliter',
    ml: 'ml',
    gallon: 'gallon',
    gal: 'gal.',
    ounce: 'ounce',
    oz: 'oz.',
    es: 'Spanish',
};

const states = {
    sk: 'Slovak',
    nl: 'Dutch ',
    en: 'English (US) ',
    'en-GB': 'English (UK) ',
    de: 'German ',
    cs: 'Czech ',
    ru: 'Russian',
    pl: 'Polish ',
    zh: 'Chinese ',
};
const errors = {
    E_PERMISSION_MISSING: 'Cannot access images. Please allow access if you want to be able to select images',
    E_PICKER_NO_CAMERA_PERMISSION: 'Cannot access camera. Please grant camera permission.',
    'images/camera-permission-missing': 'Cannot access images. Please allow access if you want to be able to select images',
    'images/cannot-run-on-simulator': 'Cannot run camera on simulator',
    E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR: 'Cannot run camera on simulator',
    'auth/user-not-found': 'There is no user corresponding to the email address',
    'auth/invalid-email': 'The email address is not valid',
    'auth/user-disabled': 'The user has been disabled.',
    'auth/too-many-login-requests': 'Too many unsuccessful login attempts. Please try again later.',
    'auth/too-many-requests': 'Too many unsuccessful requests. Please try again later.',
    'auth/email-already-in-use': 'There already exists an account with the given email address.',
    'auth/weak-password': 'The password is not strong enough. Min 6 characters',
    'auth/create-user-not-allowed':
        'email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.',
    'firestore/unavailable':
        'The service is currently unavailable. This is a most likely a transient condition and may be corrected by retrying with a backoff.',
    'firestore/permission-denied': 'The caller does not have permission to execute the specified operation.',
    'delete/user-baskets/no-user': 'Error during deletion. The user is not defined.',
    'auth/network-request-failed': 'Network error has occurred',
    'auth/user-cancelled': 'The login operation has been canceled',
    'auth/no-access-token': 'There is a problem with the access to the app',
    ErrorHttp_404: 'The resource has been not found.',
    ErrorHttp_400: 'The request cannot be processed.',
    ErrorHttp_401: 'User not authenticated.',
    ErrorHttp_403: 'Access denied.',
};
/*

     * @error auth/email-already-in-use Thrown if there already exists an account with the given email address.
     * @error auth/invalid-email Thrown if the email address is not valid.
     * @error auth/operation-not-allowed Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
     * @error auth/weak-password Thrown if the password is not strong enough.
 */
export default {
    ...texts,
    ...menu,
    ...activity,
    ...units,
    ...login,
    ...account,
    ...states,
    ...errors,
};
