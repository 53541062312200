import * as React from 'react';
import Screen from "../components/screen";
import i18n from '../i18n';
import {Colors} from "../theme";
import {setMeta} from "../helper/utils.helper";

export default class NotFound extends React.PureComponent {
    public componentDidMount() {
        setMeta(i18n.t('title_page_404'), i18n.t('desc_page_404'));
    }
    render() {
        return (
            <Screen logo={true} waitFor={false}>
                <h1 style={{color: Colors.textMuted}}>Oops! It looks like the page you are looking for is gone.</h1>
            </Screen>
        );
    }

}
