import { Snackbar } from '@material-ui/core';
import { bind } from 'decko';
import React from 'react';
import {PrettySnackbarParams} from "../screen";
import {Alert} from "@material-ui/lab";

type State = PrettySnackbarParams & {visible: boolean};
export default class GlobalSnackbar extends React.PureComponent<{}, State> {
    public readonly state: State = {
        message: '',
        severity: 'info',
        visible: false,
    };

    public render() {
        const { message, severity, visible } = this.state;
        return (
            <Snackbar open={visible} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity={severity || 'info'}>
                    {message}
                </Alert>
            </Snackbar>
        );
    }
    public show(params: PrettySnackbarParams) {
        this.setState({ message: params.message, severity: params.severity, visible: true });
        setTimeout(() => {
            this.handleClose();
        }, 5000);
    }

    @bind
    private handleClose() {
        this.setState({visible: false });
    }
}
