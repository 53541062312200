import * as React from "react";
import Lock from '@material-ui/icons/Lock';
import Done from '@material-ui/icons/Done';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import Backend from "../Backend";
import {inject, observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import Screen from "../components/screen";
import Account from "../Account";
import i18n from '../i18n';
import {action, computed, observable} from "mobx";
import {Alert} from "@material-ui/lab";
import {setMeta, timeago} from "../helper/utils.helper";
import {BasketCategory, UserInfo} from "../model";
import {Colors, DarkButton, LightButton} from "../theme";
import {CSSProperties} from "react";
import styled from "styled-components";
import {bind} from "decko";
import {DownloadOyeyku} from "./Home";
import * as firebase from 'firebase/app';
import 'firebase/analytics';
const DEFAULT_AVATAR_IMAGE = require('../assets/img/default_avatar_opacity_20.png');
const Gradient = styled.div`
    padding: 16px;
    border: 1px solid #eaeaea;
    color: #353535;
    text-shadow: 1px 1px 2px #dedede;
`;

type MatchParams = {invitationId?: string};
type Props = {backend: Backend; account: Account} & RouteComponentProps<MatchParams>;
type JoinActivityStatus = 'waiting' | 'error' | 'joined' | 'rejected' | 'connected';
@inject('backend', 'account')
@observer
export default class JoinActivity extends React.PureComponent<Props> {
    @observable
    private status: JoinActivityStatus | null =  null;
    private invitationId: string | null = null;
    private basket: any;
    private createdBy: any;
    private dynamicLink: any;
    private participant: any;
    @observable
    private errorMessage: string | null = null ;
    @observable
    private hasImage: boolean = false;
    constructor(props: Props) {
        super(props);
        this.onAccept = this.onAccept.bind(this);
    }

    componentDidMount() {
        setMeta(i18n.t('title_page_join'), i18n.t('desc_page_join'));
        this.props.account.ifInitialized.then(() => {
            if (!this.props.account.isLoggedIn) {
                this.props.history.push('/login', this.props.match.params.invitationId);
            } else {
                this.load();
                // https://firebasestorage.googleapis.com/v0/b/oyeyku-55386.appspot.com/o/baskets%2FQFRRdJC5uuT4TJtRnBdD%2Fimage?alt=media&token=277c6f93-f61e-459e-8016-8b810bdd648a
                // https://firebasestorage.googleapis.com/v0/b/oyeyku-55386.appspot.com/o/baskets%2FQFRRdJC5uuT4TJtRnBdD%2Fimage?alt=media&token=0a5420f0-7097-45b8-b5ae-0c61495152e6
                // "https://firebasestorage.googleapis.com/v0/b/oyeyku-55386.appspot.com/o/baskets%2F9oR4bdsJLTrXyIzBbkIE%2Fimage?alt=media&token=d36fd729-e53a-4df6-804f-56b3b9f3d228"
               /* const now = Date.now();
                firebase.storage().ref('baskets/QFRRdJC5uuT4TJtRnBdD').child('image_65x65').getDownloadURL().then((data: any) => {
                    console.log('getDownloadURL ' + (Date.now() - now), data);
                });
                this.hasImage = true;
                this.basket = {photoURL: 'https://firebasestorage.googleapis.com/v0/b/oyeyku-55386.appspot.com/o/baskets%2F9oR4bdsJLTrXyIzBbkIE%2Fimage?alt=media&token=d36fd729-e53a-4df6-804f-56b3b9f3d228'};
                this.dynamicLink = {};
                this.status = 'connected';
                this.errorMessage = 'asdf asdfasdf as fasdf ';
                this.status = 'joined';  */
            }
        });
    }

    @action
    private onAccept() {
        this.props.backend.joinToBasket(this.invitationId)
            .then(() => {
                firebase.analytics().logEvent('join_basket');
                this.status = 'joined';
            })
            .catch(message => {
                this.errorMessage = message;
                this.status = 'error';
            });

    }
    @bind
    private onDecline() {
        this.props.history.replace('/');
    }

    @computed
    private get isBlocked(): boolean {
        if (this.basket && Array.isArray(this.basket.blockedUsers)) {
            return this.basket.blockedUsers.indexOf(this.props.account.uid) > -1;
        }
        return false;
    }
    private get isLocked(): boolean {
        return !!this.basket && this.basket.closedBy;
    }
    private get isDynamicLinkDisabled(): boolean {
        return !!this.dynamicLink && this.dynamicLink.disabled;
    }
    private get isInvitationDisabled(): boolean {
        return this.isDisabled || this.isBlocked || this.isDynamicLinkDisabled || this.isLocked;
    }
    @computed
    private get isDisabled(): boolean {
        return !!this.participant && this.participant.disabled === true;
    }
    @action
    private load() {
        this.invitationId = this.props.match.params.invitationId || null;
        this.props.backend.loadInvitation(this.invitationId ).then(result => {
            const {basket, createdBy, dynamicLink, participant} = result;
            this.basket = basket;
            this.hasImage = !!(basket && basket.photoURL);
            this.createdBy = createdBy;
            this.dynamicLink = dynamicLink;
            this.participant = participant;
            if (this.participant && Object.keys(this.participant).length && !this.isDisabled && !this.isBlocked) {
                this.status = 'connected';
            } else if (!this.dynamicLink || !this.basket) {
                this.status = 'error';
                this.errorMessage = i18n.t('error_invitation');
            } else {
                this.status = 'waiting';
            }
        }).catch(message => {
            this.basket = null;
            this.status = 'error';
            this.errorMessage = message;
        })
    }
    private renderContent() {
        if (this.status === 'error' && (!this.dynamicLink || !this.basket)) {
            return (<Alert severity={'error'} style={{margin: '36px 0'}}>
                {this.errorMessage}
            </Alert>);
        }
        if (!this.status || !this.dynamicLink || !this.basket) {
            return null;
        }
        const basket = this.basket || {};
        return (
            <>
                <Activity
                    title={basket.title || ''}
                    description={basket.description || ''}
                    category={basket.category || BasketCategory.other}
                    progress={basket.progress || 0}
                    complete={basket.complete || false}
                    participantsCount={basket.participantsCount || 0}
                    locked={!!basket.closedBy}
                    createdAt={new Date(basket.createdAt || Date.now())}
                    createdBy={this.createdBy}
                    image={basket.photoURL}
                />
                <Gradient className={'gradient'}>
                    <AcceptActions
                        invitationDisabled={this.isInvitationDisabled}
                        message={this.errorMessage}
                        onAccept={this.onAccept}
                        onDecline={this.onDecline}
                        status={this.status} />
                </Gradient>
            </>
        );
    }
    render() {
        return (
            <Screen logo={true} contentStyle={{top: this.hasImage ? -53 : 0}}>
                {this.renderContent()}
            </Screen>
        );
    }
}
interface AcceptActionsProps {
    invitationDisabled: boolean;
    status: JoinActivityStatus;
    message: string | null;
    onAccept: () => void;
    onDecline: () => void;
}
function AcceptActions(props: AcceptActionsProps) {
    if (props.invitationDisabled) {
        return <>
            <span style={{color: Colors.danger}}>{i18n.t('error_invitation_disabled')}</span>
            <ul style={{color: Colors.danger}}>
                <li>the activity has been locked or removed</li>
                <li>you have been blocked</li>
                <li>the link has been disabled</li>
            </ul>
        </>;
    }
    if (props.status === 'error') {
        return <span style={{color: Colors.danger}}>{props.message}</span>;
    }
    if (props.status === 'connected') {
        return <span style={{color: Colors.successText}}>You are already a participant in this activity. Open the application on your mobile device.</span>;
    }
    if (props.status === 'joined') {
        return (
            <>
                <span>You just joined the activity! Download the app on your mobile device,
                    log in and start collecting items with your friends.</span>
                <DownloadOyeyku style={{marginBottom: 0}}/>
          </>
        );
    }
    if (props.status === 'waiting') {
        return <div style={{textAlign: 'center'}}>
            <p style={{fontSize: 16, marginBottom: 24, color: Colors.textStrong}}>{i18n.t('accept_activity_desc')}</p>
            <p>
                <LightButton variant="contained" onClick={props.onDecline} style={{margin: '0 16px'}}>
                    {i18n.t('cancel')}
                </LightButton>
                <DarkButton variant="contained" color={'secondary'} onClick={props.onAccept}  style={{margin: '0 16px', paddingLeft: 30, paddingRight: 30}}>
                    {i18n.t('join')}
                </DarkButton>
            </p>
        </div>;
    }
    return null;
}
interface ActivityProps {
    title?: string;
    description?: string;
    category?: number;
    progress: number;
    participantsCount?: number;
    complete?: boolean;
    locked: boolean;
    commentsCount?: number;
    createdAt?: Date | null;
    createdBy?: any;
    image?: any;
}

function Activity(props: ActivityProps) {

    const iconSize = 20;
    const color = Colors.text;
    const meta: CSSProperties =  {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 2,
    };
    const progressSeparator: CSSProperties =  {
        height: 1,
        backgroundColor: Colors.border,
        marginTop: 4,
        marginBottom: 24,
    };
    const isComplete= props.progress >= 100 || props.complete;
    return (
        <div>
            {props.image && <img alt={''} src={props.image} style={{width: '100%', height: 150, objectFit: 'cover'}} />}
            <div>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
                <p>
                    <PeopleOutline style={{marginRight: 4, verticalAlign: 'middle', width: 18,
                        height: 18}}/>
                    <span style={{fontSize: 14}}>{props.participantsCount}</span>
                </p>
                <div style={meta}>
                    <div
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}>
                        <Avatar user={props.createdBy} style={{flexShrink: 0}} />
                        <DisplayName
                            user={props.createdBy}
                            style={{flexShrink: 1}}
                            placeholder={true}
                            color={color}
                        />
                        <span
                            style={{fontSize: 14, color: color, overflow: 'hidden', flexShrink: 0}}>
                            , {timeago(props.createdAt)}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            paddingBottom: 4,
                            flexShrink: 0,
                        }}>
                        {props.locked &&
                            <Lock style={{color: color,
                                width: iconSize,
                                height: iconSize}}/> }
                        {!isComplete && <small>{props.progress}%</small>}
                        {isComplete && <Done style={{color: props.progress >= 100 ? Colors.success : Colors.textStrong}}/>}
                    </div>
                </div>
                <div style={progressSeparator}>
                    <div
                        style={{
                            height: 1,
                            width: props.progress + '%',
                            backgroundColor: Colors.success,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
function getUserImage(user: any) {
    if (user && user.photoURL) {
        return user.photoURL;
    }
    return DEFAULT_AVATAR_IMAGE;
}
export function Avatar(props: {avatarSize?: number; user: any; style?: any}) {
    const avatarWidth = props.avatarSize || 24;
    const avatarHeight = props.avatarSize || 24;
    const borderRadius = avatarWidth / 2;
    const style = props.style || {};
    return (
        <img
            alt={'avatar'}
            style={{
                width: avatarWidth,
                height: avatarHeight,
                borderRadius: borderRadius,
                marginRight: 8,
                ...style,
            }}
            src={getUserImage(props.user)}
        />
    );
}
export function DisplayName(props: {
    placeholder?: boolean;
    user: any;
    textSize?: number;
    deleted?: boolean;
    style?: any;
    color?: string;
}) {
    const displayName = UserInfo.displayName(props.user);
    if (!displayName && props.placeholder === true) {
        return (
            <div
                style={{
                    width: 80,
                    marginLeft: 8,
                    height: 10,
                    backgroundColor: '#eeeeee',
                }}
            />
        );
    }
    const fontStyle = !props.deleted ? 'normal' : 'italic';
    const color = !props.deleted ? props.color || Colors.text : Colors.danger;
    const text = !props.deleted ? displayName : i18n.t('deleted_user');
    const textSize = props.textSize || 14;
    const style = props.style || {};
    return (
        <span
            style={{
                fontStyle: fontStyle,
                color: color,
                fontSize: textSize,
                ...style,
            }}>
            {text}
        </span>
    );
}
