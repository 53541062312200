import {EMAIL_PATTERN} from "../Constants";
import i18n from '../i18n';
export async function waitFor(ms: number) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}
export function isEmpty(obj: any) {
    if (obj === null || obj === undefined) {
        return true;
    }
    if (typeof obj === 'string') {
        return obj.trim().length === 0;
    }
    if (Array.isArray(obj)) {
        return obj.length === 0;
    }
    if (typeof obj !== 'boolean' && typeof obj !== 'number') {
        const keys = Object.keys(obj);
        return keys.length === 0;
    }
    return false;
}
export function sanitizeText(txt?: string | null) {
    if (txt) {
        return txt.trim();
    }
    return '';
}
export function isEmail(emailAddress: string, validOnEmpty?: boolean) {
    if (validOnEmpty && isEmpty(emailAddress)) {
        return true;
    }
    if (isEmpty(emailAddress) || emailAddress.endsWith('.')) {
        return false;
    }
    if (emailAddress.replace(/[^@]/g, '').length !== 1) {
        return false;
    }
    return EMAIL_PATTERN.test(emailAddress);
}
export function isUrl(text: string) {
    if (isEmpty(text)) {
        return false;
    }
    let address = text.toLowerCase();
    if (!address.startsWith('http')) {
        address = 'https://' + address;
    }
    try {
        const url = new URL(address);
        return true;
    } catch (e) {
        return false;
    }
}
export function timeago(date: Date | undefined | null) {
    if (!date) {
        return '';
    }
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - date.getTime()) / 1000));
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    let params = {};
    let key = '';
    if (seconds <= 45) {
        key = 'few_seconds';
    } else if (seconds <= 90) {
        key = 'minute';
    } else if (minutes <= 45) {
        key = 'minutes';
        params = {minutes: minutes};
    } else if (minutes <= 90) {
        key = 'hour';
    } else if (hours <= 22) {
        params = {hours: hours};
        key = 'hours';
    } else if (hours <= 36) {
        key = 'day';
    } else if (days <= 25) {
        params = {days: days};
        key = 'days';
    } else if (days <= 45) {
        key = 'month';
    } else if (days <= 345) {
        params = {months: months};
        key = 'months';
    } else if (days <= 545) {
        key = 'year';
    } else {
        params = {years: years};
        key = 'years';
    }
    return i18n.t('timeago.' + key, params);
}

export function setMeta(title: string, description: string) {
    document.title = title;
    const elements = document.getElementsByTagName("META");
    const desc = elements.namedItem('description');
    if (desc) {
        desc.setAttribute('content', description);
    }
}
