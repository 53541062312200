import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

export const WhiteOverlay = styled.div.attrs(() => ({
    onClick: event => event.stopPropagation()
}))`
    align-items: center;
    background-color: rgba(158, 158, 158, 0.31);
    cursor: default;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2000;
    width: 100%;
`;

export default class LoadingIndicator extends React.PureComponent {
    render() {
        return (
            <WhiteOverlay style={{ position: 'fixed' }}>
                <CircularProgress style={{ display: 'inline-block', position: 'relative' }} />
            </WhiteOverlay>
        );
    }
}
